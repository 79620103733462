import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const Banner = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className=" ">
      <div className="banner ">
        <div
          className="max-w-screen-2xl lg:max-w-screen-2xl mx-auto
      flex md:justify-start  items-center md:h-[70vh] px-5  md:px-10"
        >
          <div className="mt-14 md:mt-0">
            <h6 className="text-bold text-[24px] text-[#000000] text-center md:text-start mb-3">
              Only With Rewards
            </h6>
            <h1 className="text-extrabold text-[34px] text-center md:text-start md:text-[60px] mb-3">
              2/$2 Ring Donuts
            </h1>
            <p className="mb-8 md:text-[23px] md:text-start text-center">
              Donut you want a sweet treat in the morning? Swing by the bakery
              case <br /> and grab 2 donuts for $2—we recommend glazed and
              chocolate frosted. <br /> And don’t forget to try the churro donut
              that’s only available for a limited time.
            </p>
            <div className="flex justify-center md:justify-start">
              <span className=" bg-[#fdc90e] rounded-full hover:text-[#faa61c] hover:font-bold hover:bg-[#fff] px-5 py-3 text-[#000000] text-[24px]">
                <Link>Find a Store</Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <div>
          <Carousel
            responsive={responsive}
            autoPlaySpeed={1000}
            infinite={true}
            autoPlay={true}
            showDots={true}
            swipeable={true}
          >
            <div className="flex gap-2  bg-blue-400 ">
              <div>
                <img src={banner1} alt="" className="w-52  md:w-96" />
              </div>
            </div>
            <div className="flex gap-2  bg-blue-400 ">
              <div>
                <img src={banner2} alt="" className="w-52  md:w-96" />
              </div>
            </div>
            <div className="flex gap-2  bg-blue-400 ">
              <div>
                <img src={banner3} alt="" className="w-52  md:w-96" />
              </div>
            </div>
          </Carousel>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
