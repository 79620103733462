import React from "react";

import { Outlet } from "react-router-dom";
import ShopCollectionHeader from "../ShopCollectionsPage/ShopCollectionsShared/ShopCollectionHeader/ShopCollectionHeader";
import ShopCollectionFooter from "../ShopCollectionsPage/ShopCollectionsShared/ShopCollectionFooter/ShopCollectionFooter";

const MainLayout = () => {
  return (
    <div>
      <ShopCollectionHeader></ShopCollectionHeader>
      <Outlet></Outlet>
      <ShopCollectionFooter></ShopCollectionFooter>
    </div>
  );
};

export default MainLayout;
