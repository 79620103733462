import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import banner from "../../../../Assets/Banner/banner1.jpg";

import control from "../../../../Assets/Icon/control.svg";
import FoodCard from "./FoodCard";
import NewArriavals from "../../../FakeApi/newArrivalApi";
const AllFoodPages = ({ fruitsCategoryName }) => {
  // console.log(fruitsCategoryName);
  const [open, setOpen] = useState(true);
  // console.log(NewArriavals);
  const filteredItems = NewArriavals.filter((items) => {
    return (
      // String(items._id) === productId ||
      items.subCategory === fruitsCategoryName
      // items.tag === "PartyDresses"
    );
  });
  // console.log(filteredItems);
  //productNumber
  const quantity = 5;

  return (
    <>
      <div className="relative ">
        <img
          alt="..."
          src={banner}
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-opacity-100 bg-gradient-to-r from-secondary"></div>

        <div className="relative">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
            <div className="flex  items-center  justify-center sm:mx-auto Capitalize text-4xl font-bold">
              <h1>{fruitsCategoryName}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex">
          <div
            className={` ${
              open ? "w-72" : "w-[5px] "
            }   p-5  pt-0 relative duration-300`}
          >
            <img
              alt="..."
              src={control}
              className={`absolute cursor-pointer -right-3 top-1 w-8 border border-1 rounded-full border-green-500 ${
                !open && "rotate-180"
              }`}
              onClick={() => setOpen(!open)}
            />
            <div className="flex gap-x-4 items-center">
              <div
                className={`origin-left font-medium duration-200 ${
                  !open && "scale-0"
                }`}
              >
                <div className=" h-80 flex items-center justify-center">
                  <div className="bg-gray-100 border w-64 h-56 p-5">
                    <p className="font-semibold text-xl">Filter Food</p>
                    <hr className="bg-gray-500 h-[2px] my-2" />
                    <div className="mt-3 ">
                      <div className="flex justify-between">
                        <p>Stock</p>
                        <div
                          className={`${
                            quantity >= 1 ? "bg-green-600" : "bg-gray-600"
                          } w-4 h-4 rounded-full my-auto`}
                        ></div>
                      </div>
                    </div>
                    <hr className="bg-gray-300 h-[2px] my-2" />

                    <div className="flex justify-between">
                      <p>Best Selling</p>
                      <select id="cars" name="cars">
                        <option value="pizza">Pizza</option>
                        <option value="burger">Burger</option>
                        <option value="sushi">Sushi</option>
                        <option value="pasta">Pasta</option>
                        <option value="taco">Taco</option>
                        <option value="salad">Salad</option>
                        <option value="steak">Steak</option>
                        <option value="sandwich">Sandwich</option>
                        <option value="ice-cream">Ice Cream</option>
                        <option value="fried-chicken">Fried Chicken</option>
                      </select>
                    </div>
                    <hr className="bg-gray-300 h-[2px] my-2" />
                    <div className="flex justify-between">
                      <p>Price</p>
                      <input
                        placeholder="20৳-1000৳"
                        className="bg-white h-5 w-32 border border-t border-l- border-r"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* body */}
          <div className=" flex-1">
            <div className="px-4 py-16   lg:py-20">
              <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  Food name
                </p>
              </div>
              <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
                {filteredItems.map((foodData) => {
                  return (
                    <Link
                      key={foodData._id}
                      to={`/shopCollections/newArrivals/${foodData._id}`}
                    >
                      <div className="border gap-2 group">
                        <div className="flex justify-center mb-4 rounded shadow overflow-hidden">
                          <img
                            className={`w-56 h-56   group-hover:scale-110 duration-300   ${
                              foodData.quantity === 0 ? "grayscale blur-sm" : ""
                            }`}
                            src={foodData.productImg}
                            alt="Person"
                          />
                        </div>
                        <div className="flex flex-col sm:text-center">
                          <p className="text-lg font-bold px-5 ">
                            {foodData.productName}
                          </p>
                          <p className="mb-5 text-md font-bold text-gray-900 ">
                            {foodData.price} $
                          </p>
                        </div>

                        <div>
                          <button class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center">
                            Add To Cart{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6 ml-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllFoodPages;
