import React, { useEffect } from "react";
import $ from "jquery";
import membership from "../../../../Assets/kRewards/smiling-young-woman-holding-slice-hot-fresh-pizza-yellow-background-banner.jpg";
import Kbanner from "../../../../Assets/kRewards/cover.jpg";
import KbannerLaptop from "../../../../Assets/kRewards/wellmary rewards-01.png";
import Kbannertab from "../../../../Assets/kRewards/mobileversion wellmarry-01.png";
import collect from "../../../../Assets/kRewards/collect rewards-01.png";
import coin from "../../../../Assets/kRewards/coin-01-01.png";
import icon from "../../../../Assets/kRewards/icon.png";
import rewardscap from "../../../../Assets/kRewards/rewardcap-01.png";
import rewardsbox from "../../../../Assets/kRewards/rewardsbox.png";
import roundshape from "../../../../Assets/kRewards/round shape-01.png";
import star from "../../../../Assets/kRewards/start-01.png";
import points from "../../../../Assets/kRewards/517368-PIPQN9-951-01.png";
import steaks from "../../../../Assets/kRewards/minimal-orange-clock-7-15-quarter-past-seven-o-clock-wall-clock-seven-fifteen.jpg";
import shop from "../../../../Assets/kRewards/cafe-coffee-shop-coffeehouse-building-with-green-awning-map-location-sign-3d-illustration-removebg-preview.png";
import one from "../../../../Assets/kRewards/JoinRewards-1-01.png";
import two from "../../../../Assets/kRewards/JoinRewards-2-01.png";
import three from "../../../../Assets/kRewards/JoinRewards-3-01.png";
import konbini from "../../../../Assets/Konbini Logo-01.jpg";
import { Link } from "react-router-dom";
const KRewards = () => {
  return (
    <div>
      <div className="relative 2xl:block hidden ">
        <img
          src={Kbanner}
          alt=""
          className=" w-full h-[30vh] object-cover object-top"
        />

        <img
          src={star}
          alt=""
          className=" w-32 absolute  2xl:bottom-0 2xl:left-[22%] zoom-rewards1"
        />
      </div>
      <div className="relative md:block hidden 2xl:hidden">
        <img src={KbannerLaptop} alt="" className=" w-full " />
      </div>
      <div className="relative block md:hidden xl:hidden lg:hidden 2xl:hidden">
        <img src={Kbannertab} alt="" className=" w-full " />
      </div>

      <section>
        <div className="py-14 px-10 md:px-20 max-w-screen-2xl mx-auto     ">
          <>
            <h1 className="text-center font-bold text-[28px] md:text-[36px] pb-14">
              Collect your Points Three ways
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10 cursor-pointer ">
              <div className="flex  flex-col py-10 px-10 items-center bg-white group shadow-md rounded-xl duration-300   hover:shadow-xl">
                <img
                  src={shop}
                  alt=""
                  className="w-80 group-hover:scale-125 pb-5 duration-300"
                />
                <div className="text-center">
                  <h2 className="text-center font-bold text-[25px] pb-5 group-hover:text-[#4fb153] duration-300">
                    Shop with Points
                  </h2>
                  <p className="px-5 text-justify">
                    Earn points on every purchase, and then spend them. Cash in
                    your points at checkout for a quick discount or redeem in
                    the menu for more offers and savings. Every 1000 points =
                    $1.
                  </p>
                </div>
              </div>
              <div className="flex  flex-col py-10 px-10 items-center bg-white group shadow-md rounded-xl duration-300   hover:shadow-xl">
                <img
                  src={points}
                  alt=""
                  className="w-60 group-hover:scale-125 pb-5 duration-300"
                />
                <div className="text-center">
                  <h2 className="text-center font-bold text-[25px] pb-5 group-hover:text-[#4fb153] duration-300">
                    Bonus Points
                  </h2>
                  <p className="px-5 text-justify">
                    Earn bonus points. With BOGO offers, select pairings and
                    weekly deals, you can earn more points, quickly. Check the
                    app, or your email, to boost your savings for every store
                    visit.
                  </p>
                </div>
              </div>
              <div className="flex  flex-col py-10 px-10 items-center bg-white group shadow-md rounded-xl duration-300   hover:shadow-xl">
                <img
                  src={steaks}
                  alt=""
                  className="w-60 group-hover:scale-125 pb-5 duration-300"
                />
                <div className="text-center">
                  <h2 className="text-center font-bold text-[25px] pb-5 group-hover:text-[#4fb153] duration-300">
                    Point Streaks
                  </h2>
                  <p className="px-5 text-justify">
                    Complete a streak when you purchase select products. It’s an
                    easy way to earn, plus you can track them in the 7-Eleven
                    app. Buy more, earn more.
                  </p>
                </div>
              </div>
            </div>
          </>
        </div>
      </section>
      <section>
        <div className="py-10">
          <div
            className="bg-cover h-[70vh] md:bg-fixed bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${membership})` }}
          >
            <h1 className="text-white text-center text-3xl md:text-5xl font-bold ">
              Membership is a Rewarding <br /> Experience
            </h1>
          </div>
        </div>
        <div className="  flex flex-col items-center justify-center gap-5 pb-20">
          <h1 className=" text-2xl md:text-5xl font-bold">Join</h1>
          <h1 className=" text-2xl md:text-4xl font-bold flex justify-center">
            {" "}
            <span>
              {" "}
              {/* <img src={konbini} alt="" className="w-10" />{" "} */}
            </span>{" "}
            REWARDS
          </h1>
          <p className="  font-bold">
            Sign up or login with your existing Willing account
          </p>
          <div className="flex justify-center md:justify-start">
            <span className=" bg-[#fdc90e] rounded-full hover:text-[#ffff] hover:font-bold hover:bg-[#4fb153] px-5 py-3 text-[#000000] text-[24px]">
              <Link>Find a Store</Link>
            </span>
          </div>
        </div>
      </section>
      <section>
        <div className=" bg-gray-100 py-20  px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10 cursor-pointer max-w-screen-2xl mx-auto">
            <div className="flex  flex-col py-10 px-10 items-center bg-gray-200 group shadow-md rounded-xl duration-300   hover:shadow-xl">
              <img
                src={one}
                alt=""
                className="w-80 group-hover:scale-125 pb-5 duration-300"
              />
              <div className="text-center">
                <p className="px-5 text-justify">
                  Download the Wella Marry app for Bonus Points, deals and
                  rewards.
                </p>
              </div>
            </div>
            <div className="flex  flex-col py-10 px-10 items-center bg-gray-200 group shadow-md rounded-xl duration-300   hover:shadow-xl">
              <img
                src={two}
                alt=""
                className="w-80 group-hover:scale-125 pb-5 duration-300"
              />
              <div className="text-center">
                <p className="px-5 text-justify">
                  Scan your app or enter your phone number at checkout to start
                  earning points. You'll also get discounts and be able to
                  redeem for rewards offers and savings.
                </p>
              </div>
            </div>
            <div className="flex  flex-col py-10 px-10 items-center bg-gray-200 group shadow-md rounded-xl duration-300   hover:shadow-xl">
              <img
                src={three}
                alt=""
                className="w-80 group-hover:scale-125 pb-5 duration-300"
              />
              <div className="text-center">
                <p className="px-5 text-justify">
                  Visit the app or login here to view deals or redeem your
                  points for freebies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 max-w-screen-2xl mx-auto">
        <div>
          <h1 className="text-center font-bold text-2xl md:text-5xl  pb-10">
            Frequently Asked Questions
          </h1>
          <hr className=" pb-10" />
        </div>
        <div class="card-wrapper content-cc px-5 cursor-pointer">
          <div class="faq-card shadow">
            <div class="faq-ilustrations">
              {/* <!-- mobile --> */}
              <img
                className="mobile pattern "
                alt=""
                src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/bg-pattern-mobile.svg"
              ></img>
              <img
                class="mobile ilust"
                alt=""
                src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/illustration-woman-online-mobile.svg"
              ></img>
              {/* <!-- mobile --> */}
            </div>

            <main class="faq-content">
              <h1>FAQ</h1>

              <div class="faq-articles">
                <article class="faq-accordion">
                  <input type="checkbox" class="tgg-title" id="tgg-title-1" />

                  <div class="faq-accordion-title">
                    <label for="tgg-title-1">
                      <h2 className="text-sm">
                        How many team members can I invite?
                      </h2>
                      <span class="arrow-icon">
                        <img
                          src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg"
                          alt=""
                          className="w-5"
                        />
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p>
                      You can invite up to 2 additional users on the Free plan.
                      There is no limit on team members for the Premium plan.
                    </p>
                  </div>
                </article>
                {/* <!-- faq accordion --> */}

                <article class="faq-accordion">
                  <input type="checkbox" class="tgg-title" id="tgg-title-2" />

                  <div class="faq-accordion-title">
                    <label for="tgg-title-2">
                      <h2>What is the maximum file upload size?</h2>
                      <span class="arrow-icon">
                        <img
                          src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg"
                          alt=""
                          className="w-5"
                        />
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p>
                      No more than 2GB. All files in your account must fit your
                      allotted storage space.
                    </p>
                  </div>
                </article>
                {/* <!-- faq accordion --> */}

                <article class="faq-accordion">
                  <input class="tgg-title" type="checkbox" id="tgg-title-3" />

                  <div class="faq-accordion-title">
                    <label for="tgg-title-3">
                      <h2>How do I reset my password?</h2>
                      <span class="arrow-icon">
                        <img
                          src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg"
                          alt=""
                          className="w-5"
                        />
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p>
                      Click “Forgot password” from the login page or “Change
                      password” from your profile page. A reset link will be
                      emailed to you.
                    </p>
                  </div>
                </article>
                {/* <!-- faq accordion --> */}

                <article class="faq-accordion">
                  <input class="tgg-title" type="checkbox" id="tgg-title-4" />

                  <div class="faq-accordion-title">
                    <label for="tgg-title-4">
                      <h2>Can I cancel my subscription?</h2>
                      <span class="arrow-icon">
                        <img
                          src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg"
                          alt=""
                          className="w-5"
                        />
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p>
                      Yes! Send us a message and we’ll process your request no
                      questions asked.
                    </p>
                  </div>
                </article>
                {/* <!-- faq accordion --> */}

                <article class="faq-accordion">
                  <input class="tgg-title" type="checkbox" id="tgg-title-5" />

                  <div class="faq-accordion-title">
                    <label for="tgg-title-5">
                      <h2>Do you provide additional support?</h2>
                      <span class="arrow-icon">
                        <img
                          src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg"
                          alt=""
                          className="w-5"
                        />
                      </span>
                    </label>
                  </div>

                  <div class="faq-accordion-content">
                    <p>
                      {" "}
                      Chat and email support is available 24/7. Phone lines are
                      open during normal business hours.
                    </p>
                  </div>
                </article>
                {/* <!-- faq accordion --> */}
              </div>
              {/* <!-- faq articles --> */}
            </main>
            {/* <!-- faq --> */}
          </div>
          {/* <!-- faq card --> */}
        </div>
        {/* <!-- card wrapper --> */}
      </section>
    </div>
  );
};

export default KRewards;
