import React from "react";
import AllFoodPages from "../ShopCollectionsShared/AllFoodPages/AllFoodPages";

const Outwear = () => {
  return (
    <>
      <AllFoodPages fruitsCategoryName="cap" />
    </>
  );
};

export default Outwear;
