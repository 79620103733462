import { RouterProvider, useLocation, useParams } from "react-router-dom";
import "./App.css";
import { router } from "./Frontend/Components/Routes/Routes";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }

    const rootElement = document.getElementById("my-app");

    if (rootElement) {
      rootElement.addEventListener("contextmenu", handleContextMenu);

      return () => {
        rootElement.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, []);

  // // For Keyboard Function
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.keyCode === 123) {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div className="relative" id="my-app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
