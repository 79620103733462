import React from "react";
import amex from "../../../../Assets/Icon/amex.png";
import applePay from "../../../../Assets/Icon/apple-pay-svgrepo-com.png";
import discover from "../../../../Assets/Icon/discover-svgrepo-com.png";
import googlePay from "../../../../Assets/Icon/google-pay-svgrepo-com.png";
import masterCard from "../../../../Assets/Icon/mastercard-svgrepo-com.png";
import meta from "../../../../Assets/Icon/meta-svgrepo-com.png";
import visa from "../../../../Assets/Icon/visa-svgrepo-com.png";

const ShopCollectionFooter = () => {
  return (
    <div className="bg-[#151414]">
      {/* footer section start */}
      <footer className="footer-section max-w-screen-2xl mx-auto">
        <div className="container ">
          {/* footer section top */}
          <div className="footer-cta pt-5 pb-5">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-5 text-center md:text-start justify-items-center items-center">
              <div className=" mb-3">
                <div className="single-cta">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span className="text-[#4fb153]">
                      1010 Avenue, sw 54321, chandigarh
                    </span>
                  </div>
                </div>
              </div>
              <div className=" mb-3">
                <div className="single-cta">
                  <i className="fas fa-phone"></i>
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span>9876543210 0</span>
                  </div>
                </div>
              </div>
              <div className=" mb-3">
                <div className="single-cta">
                  <i className="far fa-envelope-open"></i>
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span>mail@info.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer section top end*/}

          {/* footer body section */}

          <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
              <div className="md:max-w-md lg:col-span-2">
                <a
                  href="/"
                  aria-label="Go home"
                  title="Company"
                  className="inline-flex items-center"
                >
                  <svg
                    className="w-8 text-white"
                    viewBox="0 0 24 24"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke="currentColor"
                    fill="none"
                  >
                    <rect x="3" y="1" width="7" height="12"></rect>
                    <rect x="3" y="17" width="7" height="6"></rect>
                    <rect x="14" y="1" width="7" height="6"></rect>
                    <rect x="14" y="11" width="7" height="12"></rect>
                  </svg>
                  <span className="ml-2 text-xl font-bold tracking-wide text-white uppercase">
                    Company
                  </span>
                </a>
                <div className="mt-4 lg:max-w-sm">
                  <p className="text-sm text-white">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam.
                  </p>
                  <p className="mt-4 text-sm text-white">
                    Eaque ipsa quae ab illo inventore veritatis et quasi
                    architecto beatae vitae dicta sunt explicabo.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                <div>
                  <p className="font-semibold tracking-wide text-white">
                    Category
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        World
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Games
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        References
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-semibold tracking-wide text-white">
                    Business
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Web
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        eCommerce
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Business
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Entertainment
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Portfolio
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-semibold tracking-wide text-white">
                    Apples
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Media
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Brochure
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Nonprofit
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Educational
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Projects
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-semibold tracking-wide text-white">
                    Cherry
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Infopreneur
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Personal
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Wiki
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-white transition-colors duration-300 hover:text-white"
                      >
                        Forum
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
              <p className="text-sm text-white">
                © Copyright 2020 Lorem Inc. All rights reserved.
              </p>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                <img className="w-[45px] h-[40px]" src={applePay} alt="" />
                <img className="w-[45px] h-[40px]" src={discover} alt="" />
                <img className="w-[45px] h-[40px]" src={googlePay} alt="" />
                <img className="w-[45px] h-[40px]" src={masterCard} alt="" />
                <img className="w-[45px] h-[40px]" src={meta} alt="" />
                <img className="w-[45px] h-[40px]" src={visa} alt="" />
                <img className="w-[45px] h-[40px]" src={amex} alt="" />
              </div>
            </div>
          </div>
          {/* footer body section end*/}
        </div>
      </footer>
      {/* footer section end */}
    </div>
  );
};

export default ShopCollectionFooter;
