import React from "react";
import AllFoodPages from "../ShopCollectionsShared/AllFoodPages/AllFoodPages";

const Shirts = () => {
  return (
    <>
      <AllFoodPages fruitsCategoryName="shirt" />
    </>
  );
};

export default Shirts;
