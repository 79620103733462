import React from "react";
import ShopCollectionSingleCard from "./ShopCollectionSingleCard";

import food1 from "../../../../Assets/foodcard/ice-cream.jpg";
import food2 from "../../../../Assets/foodcard/orageStraw.jpg";
import food3 from "../../../../Assets/foodcard/threec-colourful.jpg";
const ShopCollectionCard = () => {
  const data = [
    {
      id: 1,
      foodName: "Ea rebum lorem dolor gubergren sit diam",
      foodPic: food1,
    },
    {
      id: 2,
      foodName: "Ea rebum lorem dolor gubergren sit diam",
      foodPic: food2,
    },
    {
      id: 3,
      foodName: "Ea rebum lorem dolor gubergren sit diam",
      foodPic: food3,
    },
  ];
  return (
    <>
      <div className="service container mx-auto py-8 px-6 h-auto ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-5">
          {data.map((foodCard) => (
            <ShopCollectionSingleCard key={foodCard.id} foodCard={foodCard} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ShopCollectionCard;
