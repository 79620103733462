import React, { useState } from "react";
import { AiFillEye, AiOutlineMail, AiOutlineGlobal } from "react-icons/ai";
import { RxCross2, RxTarget } from "react-icons/rx";
import { CiLocationOn } from "react-icons/ci";
import { RiHandHeartFill, RiHeadphoneFill } from "react-icons/ri";

import aboutus from "../../../../Assets/Aboutus/1.jpeg";
const AboutUs = () => {
  const testimonials = [
    {
      name: "Miyah Myles",
      position: "Marketing",
      photo:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=707b9c33066bf8808c934c8ab394dff6",
      text: "I've worked with literally hundreds of HTML/CSS developers and I have to say the top spot goes to this guy. This guy is an amazing developer. He stresses on good, clean code and pays heed to the details. I love developers who respect each and every aspect of a throughly thought out design and do their best to put it in code. He goes over and beyond and transforms ART into PIXELS - without a glitch, every time.",
    },
    {
      name: "June Cha",
      position: "Software Engineer",
      photo: "https://randomuser.me/api/portraits/women/44.jpg",
      text: "This guy is an amazing frontend developer that delivered the task exactly how we need it, do your self a favor and hire him, you will not be disappointed by the work delivered. He will go the extra mile to make sure that you are happy with your project. I will surely work again with him!",
    },
    {
      name: "Iida Niskanen",
      position: "Data Entry",
      photo: "https://randomuser.me/api/portraits/women/68.jpg",
      text: "This guy is a hard worker. Communication was also very good with him and he was very responsive all the time, something not easy to find in many freelancers. We'll definitely repeat with him.",
    },
    {
      name: "Renee Sims",
      position: "Receptionist",
      photo: "https://randomuser.me/api/portraits/women/65.jpg",
      text: "This guy does everything he can to get the job done and done right. This is the second time I've hired him, and I'll hire him again in the future.",
    },
    {
      name: "Jonathan Nunfiez",
      position: "Graphic Designer",
      photo: "https://randomuser.me/api/portraits/men/43.jpg",
      text: "I had my concerns that due to a tight deadline this project can't be done. But this guy proved me wrong not only he delivered an outstanding work but he managed to deliver 1 day prior to the deadline. And when I asked for some revisions he made them in MINUTES. I'm looking forward to work with him again and I totally recommend him. Thanks again!",
    },
    {
      name: "Sasha Ho",
      position: "Accountant",
      photo:
        "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?h=350&auto=compress&cs=tinysrgb",
      text: "This guy is a top notch designer and front end developer. He communicates well, works fast and produces quality work. We have been lucky to work with him!",
    },
    {
      name: "Veeti Seppanen",
      position: "Director",
      photo: "https://randomuser.me/api/portraits/men/97.jpg",
      text: "This guy is a young and talented IT professional, proactive and responsible, with a strong work ethic. He is very strong in PSD2HTML conversions and HTML/CSS technology. He is a quick learner, eager to learn new technologies. He is focused and has the good dynamics to achieve due dates and outstanding results.",
    },
  ];

  const [modal, setModal] = useState(false);
  const myFunction = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  return (
    <div>
      <div
        className="bg-cover h-[60vh] bg-fixed bg-center flex items-center justify-center"
        style={{ backgroundImage: `url(${aboutus})` }}
      >
        <div>
          <button class="text-[36px] text-white font-bold bg-[#4fb154] px-5 py-2 rounded-lg">
            About Us
          </button>
        </div>
      </div>

      <section className="max-w-screen-2xl mx-auto  py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 px-10 ">
          <div className=" ">
            <h2 className="pb-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              WHO WE ARE ?
            </h2>
            <p className="text-base text-gray-700 text-justify">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
              <div id="myDIV">
                <>
                  <p
                    onClick={() => setModal((value) => !value)}
                    class="inline-flex cursor-pointer items-center justify-center w-full px-6 py-3 my-5 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
                    data-primary="green-400"
                    data-rounded="rounded-2xl"
                    data-primary-reset="{}"
                  >
                    Read More
                    <svg
                      class="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </p>
                </>
              </div>
              <div className="fixed top-[40%] left-[40%]  -translate-y-1/3 -translate-x-1/3  z-[99999999] bg-gray-500">
                {modal && (
                  <div className="border-2 border-[#9ecc3a] p-5  bg-white shadow-lg drop-shadow-xl z-20 ">
                    <div className="">
                      <button
                        className="close-btn-form"
                        onClick={() => setModal((value) => !value)}
                      >
                        <RxCross2 className="text-[#7ac24d] text-[40px] font-bold rounded absolute -top-2 -right-2" />
                      </button>

                      <div className="px-10">
                        <h1 className=" text-base text-gray-700 py-10 ">
                          That's why our stores are open 24 hours a day, 7 days
                          a week, providing round-the-clock access to a diverse
                          range of products. From everyday essentials to unique
                          cultural items, we curate our offerings to cater to
                          the needs and desires of our customers. Authenticity
                          is at the core of everything we do. We carefully
                          select products that embody the true essence of Japan,
                          ensuring that each item represents the country's rich
                          traditions, exceptional craftsmanship, and quality.
                          Whether it's traditional arts and crafts, gourmet
                          delights, or fashion trends, our customers can trust
                          that they are getting an authentic Japanese
                          experience. Sustainability is a key focus for Well
                          Marry Japan. We believe in responsible business
                          practices that protect the environment and contribute
                          to the well-being of society. We actively seek out
                          eco-conscious solutions, support ethical sourcing, and
                          promote initiatives that prioritize environmental
                          conservation and social responsibility.
                        </h1>
                      </div>
                      <div className="flex justify-end gap-3 p-5">
                        <button
                          onClick={() => setModal((value) => !value)}
                          className="bg-red-700 text-white font-bold px-3 py-2 mt-5 rounded"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                    <small className="text-end block px-5 ">
                      powered by{" "}
                      <a
                        href="https://weerodigital.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[#0d4d98]"
                      >
                        Weero Digital
                      </a>{" "}
                    </small>
                  </div>
                )}
              </div>
            </p>
          </div>

          <div>
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
        </div>
      </section>

      <section>
        <div class="solution_cards_box grid grid-cols-1 md:grid-cols-3 gap-10 px-10 cursor-pointer py-20">
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <AiFillEye size={30} className="" id="Layer_1" />
            </div>
            <div class="solu_title">
              <h3>Vision</h3>
            </div>
            <div class="solu_description">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            {/* <div id="myDIV">
              <>
                <p
                  onClick={() => setModal((value) => !value)}
                  class="inline-flex cursor-pointer items-center justify-center w-full px-6 py-3 my-5 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
                  data-primary="green-400"
                  data-rounded="rounded-2xl"
                  data-primary-reset="{}"
                >
                  Read More
                  <svg
                    class="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </p>
              </>
            </div>
            <div className="fixed top-[40%] left-[50%]  -translate-y-2/4 -translate-x-2/4  z-[999999]">
              {modal && (
                <div className="border-2 border-[#9ecc3a] p-5  bg-white shadow-lg drop-shadow-xl z-20 ">
                  <div className="">
                    <button
                      className="close-btn-form"
                      onClick={() => setModal((value) => !value)}
                    >
                      <RxCross2 className="text-[#7ac24d] text-[40px] font-bold rounded absolute -top-2 -right-2" />
                    </button>

                    <div className="px-10">
                      <h1 className=" text-base text-gray-700 py-10 ">
                        That's why our stores are open 24 hours a day, 7 days a
                        week, providing round-the-clock access to a diverse
                        range of products. From everyday essentials to unique
                        cultural items, we curate our offerings to cater to the
                        needs and desires of our customers. Authenticity is at
                        the core of everything we do. We carefully select
                        products that embody the true essence of Japan, ensuring
                        that each item represents the country's rich traditions,
                        exceptional craftsmanship, and quality. Whether it's
                        traditional arts and crafts, gourmet delights, or
                        fashion trends, our customers can trust that they are
                        getting an authentic Japanese experience. Sustainability
                        is a key focus for Well Marry Japan. We believe in
                        responsible business practices that protect the
                        environment and contribute to the well-being of society.
                        We actively seek out eco-conscious solutions, support
                        ethical sourcing, and promote initiatives that
                        prioritize environmental conservation and social
                        responsibility.
                      </h1>
                    </div>
                    <div className="flex justify-end gap-3 p-5">
                      <button
                        onClick={() => setModal((value) => !value)}
                        className="bg-red-700 text-white font-bold px-3 py-2 mt-5 rounded"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <small className="text-end block px-5 ">
                    powered by{" "}
                    <a
                      href="https://weerodigital.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#0d4d98]"
                    >
                      Weero Digital
                    </a>{" "}
                  </small>
                </div>
              )}
            </div> */}
          </div>
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <RxTarget size={30} className="" id="Layer_1" />
            </div>
            <div class="solu_title">
              <h3>Mission</h3>
            </div>
            <div class="solu_description">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            {/* <div id="myDIV">
              <>
                <p
                  onClick={() => setModal((value) => !value)}
                  class="inline-flex cursor-pointer items-center justify-center w-full px-6 py-3 my-5 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
                  data-primary="green-400"
                  data-rounded="rounded-2xl"
                  data-primary-reset="{}"
                >
                  Read More
                  <svg
                    class="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </p>
              </>
            </div>
            <div className="fixed top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4  z-[999999]">
              {modal && (
                <div className="border-2 border-[#9ecc3a] p-5  bg-white shadow-lg drop-shadow-xl z-20 ">
                  <div className="">
                    <button
                      className="close-btn-form"
                      onClick={() => setModal((value) => !value)}
                    >
                      <RxCross2 className="text-[#7ac24d] text-[40px] font-bold rounded absolute -top-2 -right-2" />
                    </button>

                    <div className="px-10">
                      <h1 className=" text-base text-gray-700 py-10 ">
                        That's why our stores are open 24 hours a day, 7 days a
                        week, providing round-the-clock access to a diverse
                        range of products. From everyday essentials to unique
                        cultural items, we curate our offerings to cater to the
                        needs and desires of our customers. Authenticity is at
                        the core of everything we do. We carefully select
                        products that embody the true essence of Japan, ensuring
                        that each item represents the country's rich traditions,
                        exceptional craftsmanship, and quality. Whether it's
                        traditional arts and crafts, gourmet delights, or
                        fashion trends, our customers can trust that they are
                        getting an authentic Japanese experience. Sustainability
                        is a key focus for Well Marry Japan. We believe in
                        responsible business practices that protect the
                        environment and contribute to the well-being of society.
                        We actively seek out eco-conscious solutions, support
                        ethical sourcing, and promote initiatives that
                        prioritize environmental conservation and social
                        responsibility.
                      </h1>
                    </div>
                    <div className="flex justify-end gap-3 p-5">
                      <button
                        onClick={() => setModal((value) => !value)}
                        className="bg-red-700 text-white font-bold px-3 py-2 mt-5 rounded"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <small className="text-end block px-5 ">
                    powered by{" "}
                    <a
                      href="https://weerodigital.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#0d4d98]"
                    >
                      Weero Digital
                    </a>{" "}
                  </small>
                </div>
              )}
            </div> */}
          </div>

          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <RiHandHeartFill size={30} className="" id="Layer_1" />
            </div>
            <div class="solu_title">
              <h3>Value</h3>
            </div>
            <div class="solu_description">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              {/* <button type="button" class="read_more_btn">
                  Read More
                </button> */}
            </div>
            {/* <div id="myDIV">
              <>
                <p
                  onClick={() => setModal((value) => !value)}
                  class="inline-flex cursor-pointer items-center justify-center w-full px-6 py-3 my-5 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
                  data-primary="green-400"
                  data-rounded="rounded-2xl"
                  data-primary-reset="{}"
                >
                  Read More
                  <svg
                    class="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </p>
              </>
            </div>
            <div className="fixed top-[40%] left-[50%]  -translate-y-2/4 -translate-x-2/4  z-[999999]">
              {modal && (
                <div className="border-2 border-[#9ecc3a] p-5  bg-white shadow-lg drop-shadow-xl z-20 ">
                  <div className="">
                    <button
                      className="close-btn-form"
                      onClick={() => setModal((value) => !value)}
                    >
                      <RxCross2 className="text-[#7ac24d] text-[40px] font-bold rounded absolute -top-2 -right-2" />
                    </button>

                    <div className="px-10">
                      <h1 className=" text-base text-gray-700 py-10 ">
                        That's why our stores are open 24 hours a day, 7 days a
                        week, providing round-the-clock access to a diverse
                        range of products. From everyday essentials to unique
                        cultural items, we curate our offerings to cater to the
                        needs and desires of our customers. Authenticity is at
                        the core of everything we do. We carefully select
                        products that embody the true essence of Japan, ensuring
                        that each item represents the country's rich traditions,
                        exceptional craftsmanship, and quality. Whether it's
                        traditional arts and crafts, gourmet delights, or
                        fashion trends, our customers can trust that they are
                        getting an authentic Japanese experience. Sustainability
                        is a key focus for Well Marry Japan. We believe in
                        responsible business practices that protect the
                        environment and contribute to the well-being of society.
                        We actively seek out eco-conscious solutions, support
                        ethical sourcing, and promote initiatives that
                        prioritize environmental conservation and social
                        responsibility.
                      </h1>
                    </div>
                    <div className="flex justify-end gap-3 p-5">
                      <button
                        onClick={() => setModal((value) => !value)}
                        className="bg-red-700 text-white font-bold px-3 py-2 mt-5 rounded"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <small className="text-end block px-5 ">
                    powered by{" "}
                    <a
                      href="https://weerodigital.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#0d4d98]"
                    >
                      Weero Digital
                    </a>{" "}
                  </small>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <section className="max-w-screen-2xl mx-auto ">
          <h1 className="font-bold text-[36px] text-center py-10">
            Clients Reviews
          </h1>
          <div>
            <div class="main">
              <div class="testimonials">
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial1"
                  checked
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial2"
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial3"
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial4"
                />
                <div class="testimonials-inner">
                  <div class="testimonial">
                    <div class="testimonial-photo">
                      <div class="photo-background"></div>
                      <div class="photo-author"></div>
                    </div>
                    <div class="testimonial-text pt-10">
                      <p>
                        Wait a second... you're telling me this testimonials
                        slider is powered solely by CSS? That's some next-level
                        web wizardry! I'm sold! Give me that mind-blowing slider
                        to my website!
                      </p>
                    </div>
                    <div class="testimonial-author">Henry Schwengle</div>
                  </div>
                  <div class="testimonial">
                    <div class="testimonial-photo">
                      <div class="photo-background"></div>
                      <div class="photo-author"></div>
                    </div>
                    <div class="testimonial-text">
                      <p>
                        The older I get, the more I understand why roosters just
                        scream to start the day.
                      </p>
                    </div>
                    <div class="testimonial-author">Mr. George Robert</div>
                  </div>
                  <div class="testimonial">
                    <div class="testimonial-photo">
                      <div class="photo-background"></div>
                      <div class="photo-author"></div>
                    </div>
                    <div class="testimonial-text">
                      <p>
                        Two of my friends have never met each other. Before they
                        spoke, I told both of them that the other is a bit deaf.
                        They shouted at each other for a few minutes before they
                        realized that I'm an asshole.
                      </p>
                    </div>
                    <div class="testimonial-author">Doodle Wobblepants</div>
                  </div>
                  <div class="testimonial">
                    <div class="testimonial-photo">
                      <div class="photo-background"></div>
                      <div class="photo-author"></div>
                    </div>
                    <div class="testimonial-text">
                      <p>
                        Turning on your lights and sirens after losing a drag
                        race is just poor sportsmanship, man.
                      </p>
                    </div>
                    <div class="testimonial-author">John "Vroom" Cena</div>
                  </div>
                </div>
                <div class="testimonials-arrows">
                  <div class="arrow arrow-left">
                    <label for="input-testimonial1"></label>
                    <label for="input-testimonial2"></label>
                    <label for="input-testimonial3"></label>
                    <label for="input-testimonial4"></label>
                    <span></span>
                  </div>
                  <div class="arrow arrow-right">
                    <label for="input-testimonial1"></label>
                    <label for="input-testimonial2"></label>
                    <label for="input-testimonial3"></label>
                    <label for="input-testimonial4"></label>
                    <span></span>
                  </div>
                </div>
                <div class="testimonials-bullets">
                  <label for="input-testimonial1">
                    <div class="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label for="input-testimonial2">
                    <div class="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label for="input-testimonial3">
                    <div class="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label for="input-testimonial4">
                    <div class="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 py-20 px-0 items-center justify-items-center ">
          <div>
            <img
              src="https://i0.wp.com/wellmarry.com/wp-content/uploads/2022/12/Well-Marry-Logo-H-B.png?fit=7988%2C5780&ssl=1"
              alt="logo"
              className="w-32"
            />
          </div>
          <div>
            <h1
              className="flex gap-3 items-center justify-center py-2 font-bold text-[16px]
              "
            >
              <CiLocationOn size={20} /> Factory Address:
            </h1>
            <p>
              Rupayan Garden, Choto Kaliyakoir, Birulia, Savar, Dahka,
              Bangladesh.
            </p>
          </div>
          <div>
            <p
              className="flex gap-3 items-center  font-bold text-[16px]
              "
            >
              <RiHeadphoneFill /> Hotline Number: 0100000000
            </p>
            <p
              className="flex gap-3 items-center  font-bold text-[16px]
              "
            >
              <AiOutlineMail /> Email: info@wellmarry.com.bd
            </p>
            <p
              className="flex gap-3 items-center  font-bold text-[16px]
              "
            >
              <AiOutlineGlobal /> Website: www.wellmarry.com
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
