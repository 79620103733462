import React from "react";
import { Link } from "react-router-dom";
import pizza from "../../../../Assets/Images/img_freepik_com-hand-holding-smartphone-with-pizza_184700-2024 (1)_low.png";
const Rewards = () => {
  return (
    <div className="bg-[#000000] ">
      <div className="grid grid-cols-1 md:grid-cols-3 max-w-screen-2xl mx-auto py-10  md:gap-40 px-5">
        <div className="order-2 md:order-1 bg-fixed">
          <img src={pizza} alt="" className="md:-rotate-45 " />
        </div>
        <div className=" col-span-2 text-[#fff] md:font-bold  flex flex-col text-center  md:justify-center md:items-start px-3 md:px-0 order-1 md:order-2">
          <h5 className="text-[34px] md:text-[24px] mb-3 ">Rewards</h5>
          <h5 className="text-[24px] md:text-[40px] mb-3 ">
            Join. Earn. Get Rewarded.
          </h5>
          <p className="md:text-[22px]">
            Get Exclusive Deals, Bonus Points, Fuel Rewards and More!
          </p>
          <div className="mt-10 w-full">
            <span className=" bg-white rounded-full hover:text-[#000] hover:bg-[#fdc90e]  px-5 py-1 md:py-3 text-[#000000] md:text-[22px]">
              <Link>Get The App</Link>
            </span>
            <span className=" ml-5 rounded-full hover:text-[#fdc90e] text-[#fff] md:text-[24px] hover:underline">
              <Link>Learn More</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
