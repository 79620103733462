import React from "react";
import { Link } from "react-router-dom";
import hiringDes from "../../../../Assets/hiringDes.jpg";
import hiringTab from "../../../../Assets/hiringtab.jpeg";

const Drinks = () => {
  return (
    <div className="max-w-screen-2xl mx-auto relative pt-14">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20  md:py-32 px-5 ">
        <div className="text-center ">
          <div className="flex justify-center items-center">
            <img
              src="https://img.freepik.com/free-vector/cocktail-glass-white-background_1308-112410.jpg"
              alt=""
              className="mb-10 w-96 "
            />
          </div>
          <div className="text-center">
            <p className="text-[24px] md:text-[30px] font-bold">
              Buy 1, Get 1 M&M’S® Share Size for $1*
            </p>
            <div className="flex  justify-center items-center mt-5">
              <span className=" bg-[#7d9a3d] rounded-full hover:text-[#000000] hover:bg-[#fdc90e]  px-5 py-1 md:py-3 text-[#000000] text-[22px]">
                <Link>Find a Store</Link>
              </span>
            </div>
          </div>
        </div>
        <div className="text-center ">
          <div className="flex justify-center items-center">
            <img
              src="https://img.freepik.com/free-vector/bellini-cocktail-glass_1308-112552.jpg"
              alt=""
              className="mb-7 w-96"
            />
          </div>
          <div className="text-center">
            <p className="text-[24px] md:text-[30px] font-bold">
              Buy 1, Get 1 M&M’S® Share Size for $1*
            </p>
            <div className="flex  justify-center items-center mt-5">
              <span className=" bg-[#f6836e]  rounded-full hover:text-[#000] hover:bg-[#fdc90e]  px-5 py-1 md:py-3 text-[#000000] text-[22px]">
                <Link>Find a Store</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" hidden md:block"
        style={{ backgroundImage: `url(${hiringDes})` }}
      >
        <div className="  text-[#000000]  py-5 ">
          <div className="flex justify-center gap-10">
            <div>
              <h1 className="font-bold text-[20px]  md:text-[30px] text-center">
                {" "}
                We're Hiring
              </h1>
              <p className="text-[20px] text-center">
                Employees can save $15 !
              </p>
            </div>
            <div className="flex  justify-center items-center mt-5">
              <span className=" bg-[#fff] rounded-full hover:text-[#fff] hover:bg-[#fdc90e] px-5 py-1 md:py-3 text-[#000000] ">
                <Link>Apply Now</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" md:hidden block h-96 relative my-10"
        style={{ backgroundImage: `url(${hiringTab})` }}
      >
        <div className="  text-[#000000] absolute top-[70%] left-[50%]  -translate-y-2/4 -translate-x-2/4 ">
          <div className="  gap-10">
            <div>
              <h1 className="font-bold text-[20px]  md:text-[30px] text-center">
                {" "}
                We're Hiring
              </h1>
              <p className="text-[20px] text-center">
                Employees can save $15 !
              </p>
            </div>
            <div className="flex  justify-center items-center mt-5">
              <span className=" bg-[#fff] rounded-full hover:text-[#fff] hover:bg-[#fdc90e] px-5 py-1 md:py-3 text-[#000000] ">
                <Link>Apply Now</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drinks;
