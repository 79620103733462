//bakery
import bakery1 from "../../Assets/shopkcollections/shopKCollections/Food/bakery/Apple Fritter.jpeg";
import bakery2 from "../../Assets/shopkcollections/shopKCollections/Food/bakery/Blueberry Muffin.jpg";
import bakery3 from "../../Assets/shopkcollections/shopKCollections/Food/bakery/Brookie.jpeg";
import bakery4 from "../../Assets/shopkcollections/shopKCollections/Food/bakery/Mini Donuts.jpg";
import bakery5 from "../../Assets/shopkcollections/shopKCollections/Food/bakery/Packaged Blueberry Mini Muffins.jpeg";

// breakfast

import breakfast1 from "../../Assets/shopkcollections/shopKCollections/Food/breakfast/English Muffin with Sausage, Egg & Cheese.png";
import breakfast2 from "../../Assets/shopkcollections/shopKCollections/Food/breakfast/Maple Flavored Sausage, Egg and Cheese Taquito.jpeg";
import breakfast3 from "../../Assets/shopkcollections/shopKCollections/Food/breakfast/Sausage Biscuit.jpg";
import breakfast4 from "../../Assets/shopkcollections/shopKCollections/Food/breakfast/Sausage, Egg & Cheese Croissant.jpeg";

// candy

import candy1 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Gummi Bears.jpg";
import candy2 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Gummi Blue Sharks.jpeg";
import candy3 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Gummi Peach Rings.jpeg";
import candy4 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Gummi Worms.jpg";
import candy5 from "../../Assets/shopkcollections/shopKCollections/Food/candy/HARIBO Goldbears.jpg";
import candy6 from "../../Assets/shopkcollections/shopKCollections/Food/candy/HERSHEY'S Milk Chocolate with Almonds.jpg";
import candy7 from "../../Assets/shopkcollections/shopKCollections/Food/candy/HERSHEY'S Milk Chocolate.jpeg";
import candy8 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Kit-Kat Wafer Bar, King Size.png";
import candy9 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Select Sour Gummi Bears.jpeg";
import candy10 from "../../Assets/shopkcollections/shopKCollections/Food/candy/Sour Neon Gummi Worms.jpeg";

// iceCeram

import iceCream1 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Chocolate Covered Strawberry Pint.jpeg";
import iceCream2 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Double Cookie Dough Pint.jpg";
import iceCream3 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Mint Cookies 'n' Cream Pint.jpeg";
import iceCream4 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Sea Salt Caramel Pint.jpeg";
import iceCream5 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Select Banana Cream Pie Pint.jpg";
import iceCream6 from "../../Assets/shopkcollections/shopKCollections/Food/iceCream/Strawberry Banana Shortcake Pint.jpg";

// snacks

import snacks1 from "../../Assets/shopkcollections/shopKCollections/Food/Snacks/Jhalmuri.jpg";
import snacks2 from "../../Assets/shopkcollections/shopKCollections/Food/Snacks/Le Vegas.jpeg";
import snacks3 from "../../Assets/shopkcollections/shopKCollections/Food/Snacks/Mr. Twist.jpeg";
import snacks4 from "../../Assets/shopkcollections/shopKCollections/Food/Snacks/Pringles.jpeg";
import snacks5 from "../../Assets/shopkcollections/shopKCollections/Food/Snacks/Snack Factory.jpeg";

//pizza

import pizza1 from "../../Assets/shopkcollections/shopKCollections/Food/pizza/Breakfast Pizza.jpeg";
import pizza2 from "../../Assets/shopkcollections/shopKCollections/Food/pizza/Cheese Pizza.jpg";
import pizza3 from "../../Assets/shopkcollections/shopKCollections/Food/pizza/Meat Pizza.jpg";
import pizza4 from "../../Assets/shopkcollections/shopKCollections/Food/pizza/Pepperoni Pizza.png";

// freshCilled
import freshChilled1 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Chicken Caesar Pasta Salad.jpeg";
import freshChilled2 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Chicken Caesar Salad.jpg";
import freshChilled3 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Chicken Salad Sandwich.jpeg";
import freshChilled4 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Egg Salad Sandwich.jpeg";
import freshChilled5 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/freshChilled.jpg";
import freshChilled6 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Fruit Blend Fruit Cup.jpg";
import freshChilled7 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Fruit Harvest Blend Fruit Cup.png";
import freshChilled8 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Garden Side Salad.jpg";
import freshChilled9 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Strawberry Cup.jpeg";
import freshChilled10 from "../../Assets/shopkcollections/shopKCollections/Food/fresh Chilled/Turkey & Cheese Sub.jpeg";

const FoodApi = [
  // bakery (6)
  {
    id: 1,
    foodName: "Mini Donuts",
    quantity: 4,
    price: 50,
    foodImage: bakery4,
    description:
      "Woke up with a sweet tooth? Enjoy four different kinds of mini yellow cake donuts: Chocolate, Cinnamon Sugar, Crunch and Powdered Sugar. All for you, all ready to go.",
    subCategory: "bakery",
    category: "food",
    newest: true,
    rating: 4,
  },

  {
    id: 2,
    foodName: "Packaged Blueberry Mini Muffins",
    quantity: 4,
    price: 50,
    foodImage: bakery5,
    description:
      "These blueberry mini muffins pack a punch in every mini, berry-filled bite. Convenient and ready to snack, just pop these mini muffins in your mouth for a portion that's just right. They're the perfect travel companion for those on-the-go.",
    subCategory: "bakery",
    category: "food",

    newest: true,
    rating: 4,
  },

  {
    id: 3,
    foodName: "Apple Fritter",
    quantity: 4,
    price: 50,
    foodImage: bakery1,
    description:
      "Made fresh daily in our local bakeries just for you, our apple fritters are that sweet-yet-tart treat you need to tame your sweet tooth.",
    subCategory: "bakery",
    category: "food",
    newest: true,
    rating: 4,
  },

  {
    id: 4,
    foodName: "Blueberry Muffin",
    quantity: 4,
    price: 50,
    foodImage: bakery2,
    description:
      "Our muffins are made fresh, daily in our local bakeries. These are light, fluffy, and filled with flavorful, tangy blueberries. Grab a blueberry muffin at a store near you.",
    subCategory: "bakery",
    category: "food",
    newest: true,
    rating: 4,
  },

  {
    id: 5,
    foodName: "Brookie",
    quantity: 4,
    price: 50,
    foodImage: bakery3,
    description:
      "Sometimes you can’t figure out what will cure your sweet tooth. Now you don’t have to pick. 50% brownie plus 50% cookie means 100% satisfaction.",
    subCategory: "bakery",
    category: "food",
    newest: true,
    rating: 4,
  },

  {
    id: 6,
    foodName: "Brookie",
    quantity: 4,
    price: 50,
    foodImage: bakery3,
    description:
      "You know what pairs well with chocolate? More chocolate. Our fudge brownie is full of chocolate chips. And you’ll be full of chocolate-y goodness. How many more times can we say chocolate?",
    subCategory: "bakery",
    category: "food",
    newest: true,
    rating: 4,
  },

  // breakfast

  {
    id: 1,
    snacksName: "English Muffin with Sausage, Egg & Cheese",
    quantity: 10,
    price: 50,
    snacksImg: breakfast1,
    description:
      "Cure your case of the morning hangries with a breakfast sandwich to rival all sandwiches. Hot, savory sausage topped with cheddar cheese and a fried egg patty with cracked black pepper, piled high on a soft English muffin that soaks up all the scrumptiousness in its flavor pockets (what we’re calling all those nooks and crannies). Each bite will leave you satisfied yet craving more.",
    subCategory: "breakfast",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "cheese",
  },

  {
    id: 2,
    snacksName: "Maple Flavored Sausage, Egg and Cheese Taquito",
    quantity: 10,
    price: 50,
    snacksImg: breakfast2,
    description:
      "Can’t decide between sweet or savory? We get it. Our limited edition Maple Flavored Sausage, Egg and Cheese Taquito features savory pork sausage, whole scrambled eggs and cheddar cheese stuffed inside a maple-flavored tortilla. No decisions necessary.",
    subCategory: "breakfast",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "sausage",
  },

  {
    id: 3,
    snacksName: "Sausage, Egg & Cheese Croissant",
    quantity: 10,
    price: 50,
    snacksImg: breakfast4,
    description:
      "Start your day with something savory. Our new Mini Spicy Breakfast Empanadas are made to perfection with sausage, egg, cheese and peppers. A kick of spice to kickstart your day.",
    subCategory: "breakfast",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "sausage",
  },

  {
    id: 4,
    snacksName: "Sausage Biscuit",
    quantity: 10,
    price: 50,
    snacksImg: breakfast3,
    description:
      "Breakfast has an unparalleled leader of the pack, the Sausage Biscuit. A warm, flaky biscuit cradles our hot, savory sausage like precious cargo. It’s a classic breakfast that fits perfectly in your hand (and mouth), making this Sausage Biscuit the best on-the-go meal for those on-the-go moments.",
    subCategory: "breakfast",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "egg",
  },

  {
    id: 5,
    snacksName: "Sausage, Egg & Cheese Croissant",
    quantity: 10,
    price: 50,
    snacksImg: breakfast4,
    description:
      "Breakfast has an unparalleled leader of the pack, the Sausage Biscuit. A warm, flaky biscuit cradles our hot, savory sausage like precious cargo. It’s a classic breakfast that fits perfectly in your hand (and mouth), making this Sausage Biscuit the best on-the-go meal for those on-the-go moments.",
    subCategory: "breakfast",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "cheese",
  },

  // candy

  {
    id: 1,
    snacksName: "KINDER JOY®",
    quantity: 10,
    price: 50,
    snacksImg: candy1,
    description:
      "A delicious treat made of two soft cream layers. KINDER JOY® has one sweet milk-cream flavored and one cocoa flavored. Nestled into the creamy layers are two round, chocolate-covered wafer bites that are filled with sweet cocoa cream, to be eaten with the included spoon. The other half of the egg contains an exciting surprise toy!",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "cocoa",
    size: "standard-size",
    type: "chocolate",
  },

  {
    id: 2,
    snacksName: "Select Sour Gummi Bears",
    quantity: 10,
    price: 50,
    snacksImg: candy9,
    description:
      "Surprise your taste buds with our sour gummi bears. These gummis are the sour level-upped version of our original gummis. Feeling adventurous? These bears were made for your mouth.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 3,
    snacksName: "Select Gummi Bears",
    quantity: 10,
    price: 50,
    snacksImg: candy1,
    description:
      "Fun, flavorful, and adorable. Our 7-Select Gummi Bears are so cute you could just eat them up…one at a time, handfuls at a time…we won’t judge – we get it. With 12 different gummi flavors, each better than the last, they’re hard to resist.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 4,
    snacksName: "Select Gummi Blue Sharks",
    quantity: 10,
    price: 50,
    snacksImg: candy2,
    description:
      "Terrifyingly delicious, blue gummies. Throw a few of these soft and chewy kings of the sea into your mouth and prepare for a tidal wave of flavor. Our 7-SELECT Gummi Sharks are tangy, with just the right amount of sweetness.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 5,
    snacksName: "Select Gummi Peach Rings",
    quantity: 10,
    price: 50,
    snacksImg: candy3,
    description:
      "Rings of tangy, peach delight. A soft, chewy candy with a perfect bite and a sugary sweet finish. Packed with flavor and fun, these 7-Select gummi rings are just peachy.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 6,
    snacksName: "Select Gummi Worms",
    quantity: 10,
    price: 50,
    snacksImg: candy4,
    description:
      "The tangiest, most delectable worms you’ve ever tasted. Grab a bag and get digging. These gummi worms are practically screaming “EAT ME!”.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 7,
    snacksName: "Select Gummi Worms",
    quantity: 10,
    price: 50,
    snacksImg: candy4,
    description:
      "The tangiest, most delectable worms you’ve ever tasted. Grab a bag and get digging. These gummi worms are practically screaming “EAT ME!”.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 8,
    snacksName: "Select Gummi Worms",
    quantity: 10,
    price: 50,
    snacksImg: candy4,
    description:
      "The tangiest, most delectable worms you’ve ever tasted. Grab a bag and get digging. These gummi worms are practically screaming “EAT ME!”.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 9,
    snacksName: "Select Sour Neon Gummi Worms",
    quantity: 10,
    price: 50,
    snacksImg: candy10,
    description:
      "Sweet, sour, neon gummi perfection. These gummies are the sour, neon, level-upped version of our original gummies. Feeling adventurous? These worms were made for your mouth.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "sour",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 10,
    snacksName: "HARIBO Goldbears",
    quantity: 10,
    price: 50,
    snacksImg: candy5,
    description:
      "The classic gummi bear. HARIBO Goldbears have been the gummi candy gold standard worldwide for over 90 years. Each bag contains five fruity flavors- Lemon, Orange, Pineapple, Raspberry, and Strawberry.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "",
    size: "standard-size",
    type: "Gummies",
  },

  {
    id: 11,
    snacksName: "HERSHEY'S Milk Chocolate",
    quantity: 10,
    price: 50,
    snacksImg: candy7,
    description:
      "The smooth, go-to Smores staple at ever campfire. You can’t mention chocolate without mentioning HERSHEY'S. Made with farm fresh milk, this HERSHEY’S Chocolate Bar makes life delicious. Unwrap a bar, break off a piece, enjoy and repeat.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "milk-chocolate",
    size: "standard-size",
    type: "chocolate",
  },

  {
    id: 12,
    snacksName: "HERSHEY'S Milk Chocolate with Almonds",
    quantity: 10,
    price: 50,
    snacksImg: candy6,
    description:
      "Delicious chocolate, with the crunch you crave. These are more than just candy bars. They’re chances to stop and savor life’s sweeter side. Each bite is filled with crunchy whole almonds and classic HERSHEY’S Milk Chocolate.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "milk-chocolate",
    size: "standard-size",
    type: "chocolate",
  },

  {
    id: 13,
    snacksName: "Kit-Kat Wafer Bar, King Size",
    quantity: 10,
    price: 50,
    snacksImg: candy8,
    description:
      "We all want a piece of that Kit-Kat Wafer Bar. Crunchy, little wafers covered and layered in delectable chocolate. It’s impossible not to lean back and take a break with one.",
    subCategory: "candy",
    category: "food",
    rating: 4,
    newest: true,
    flavors: "caramel",
    size: "king-size",
    type: "chocolate",
  },

  // ice ceram
  {
    id: 1,
    snacksName: "Select Banana Cream Pie Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream5,
    description:
      "Banana cream Heaven-on-a-spoon. This pint of Heaven is filled with banana ice cream that is intertwined with fluffy ribbons of whipped cream. To top it all off, pie crust – bits of flaky pie crust. Yeah, it’s real, and at your local 7-Eleven.",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "banana",
  },

  {
    id: 2,
    snacksName: "Chocolate Covered Strawberry Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream1,
    description:
      "Creamy ice cream with chocolate chunks and tangy strawberries.",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "strawberry",
  },

  {
    id: 3,
    snacksName: "Double Cookie Dough Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream2,
    description:
      "Delicious chunks of cookie dough in fluffy vanilla ice cream. We believe that you should never be forced to hunt for the cookie dough in mountains of ice cream. So, we created an ice cream that promises a dreamland of cookie dough. Now, you can enjoy bite after bite of doughy-goodness, while never taking your eyes off that movie.",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "cookie dough",
  },

  {
    id: 4,
    snacksName: "Mint Cookies 'n' Cream Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream3,
    description:
      "Cool, minty ice cream blended with bits chocolate cookie and cream sandwiches. Welcome to texture Heaven. Our smooth, vanilla ice cream, with a chocolatey-crunch surprise is a different experience with every bite. It’s great for sharing…",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "cookies-cream",
  },

  {
    id: 5,
    snacksName: "Sea Salt Caramel Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream4,
    description:
      "This pint is filled with tangy sea salt, and warm caramel. It’s the perfect balance of salt and sugar, creating a pallet of warmth and excitement, all at once. Swirls of salted caramel intertwine with smooth, crisp, vanilla ice cream in this delectable ride for your taste buds.",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "caramel",
  },

  {
    id: 6,
    snacksName: "Strawberry Banana Shortcake Pint",
    quantity: 10,
    price: 50,
    snacksImg: iceCream6,
    description:
      "Pieces of moist, strawberry shortcake and juicy strawberries. What’s not to enjoy about our ice cream that marries luscious cushions of strawberry shortcake and ripened strawberries, with our creamy vanilla ice cream?",
    subCategory: "iceCream",
    category: "food",
    rating: 4,
    newest: true,
    flavor: "strawberry",
  },

  // snack
  {
    id: 1,
    snacksName: "Mr. Twist",
    quantity: 10,
    price: 50,
    snacksImg: snacks3,
    description:
      "Mr. Twist is a popular Bangladeshi chips brand that offers twisted potato chips in various flavors. Some of their flavors include Spicy, Tomato, Sour Cream & Onion, Barbecue, and Cheese.",
    subCategory: "snacks",
    category: "food",
    rating: 4,
    newest: true,
    brand: "",
    flavor: "potato",
    type: "chips",
  },

  {
    id: 2,
    snacksName: "Jhalmuri",
    quantity: 10,
    price: 50,
    snacksImg: snacks1,
    description:
      "Jhalmuri is a traditional Bengali snack made from puffed rice, vegetables, and spices. It is commonly sold by street vendors and is a popular choice for a quick and flavorful snack.",
    subCategory: "snacks",
    category: "food",
    rating: 5,
    newest: true,
    brand: "",
    flavor: "jalapeno",
    type: "",
  },

  {
    id: 3,
    snacksName: "Snack Factory",
    quantity: 10,
    price: 50,
    snacksImg: snacks5,
    description:
      "Snack Factory is a Bangladeshi brand that offers a range of snacks, including chips. They offer flavors like Masala, Tomato, Barbecue, Cheese, and more.",
    subCategory: "snacks",
    category: "food",
    rating: 5,
    newest: true,
    brand: "",
    flavor: "",
    type: "",
  },

  {
    id: 4,
    snacksName: "Le Vegas",
    quantity: 10,
    price: 50,
    snacksImg: snacks2,
    description:
      "Le Vegas is a Bangladeshi brand that offers a range of snacks, including chips. They offer flavors like Masala, BBQ, Tomato, and more.",
    subCategory: "snacks",
    category: "food",
    rating: 5,
    newest: true,
    brand: "",
    flavor: "sea-salt",
    type: "chips",
  },

  {
    id: 5,
    snacksName: "Pringles",
    quantity: 10,
    price: 50,
    snacksImg: snacks4,
    description:
      "Pringles is a global brand that offers a range of potato chips in various flavors. In Bangladesh, you can find flavors like Original, Sour Cream & Onion, BBQ, Salt & Vinegar, and more.",
    subCategory: "snacks",
    category: "food",
    rating: 5,
    newest: true,
    brand: "",
    flavor: "chocolate-chip",
    type: "chips",
  },

  // pizza

  {
    id: 1,
    snacksName: "Meat Pizza",
    quantity: 10,
    price: 50,
    snacksImg: pizza3,
    description:
      "It’s a mouthful in more ways than one… seven to be exact. Our 7-Meat Pizza is topped with Chicago-Style Sausage, meaty sausage and beef crumbles, crispy pepperoni, smoky bacon, Canadian Bacon and diced ham.",
    subCategory: "pizza",
    category: "food",
    rating: 4,
    newest: true,
  },

  {
    id: 2,
    snacksName: "Breakfast Pizza",
    quantity: 10,
    price: 50,
    snacksImg: pizza1,
    description:
      "This pizza is a breakfast lover’s dream with gravy sauce, mozzarella and cheddar cheeses, and crumbled sausage. Now you can ¬finally enjoy pizza at all hours of the day.",
    subCategory: "pizza",
    category: "food",
    rating: 4,
    newest: true,
  },

  {
    id: 3,
    snacksName: "Cheese Pizza",
    quantity: 10,
    price: 50,
    snacksImg: pizza2,
    description:
      "More cheese means more fun, right? 100% Real® Mozzarella made from whole milk piled on our signature sauce made from vine-ripened, California tomatoes. What’s not to like? Order one in-store today.",
    subCategory: "pizza",
    category: "food",
    rating: 4,
    newest: true,
  },
  {
    id: 4,
    snacksName: "Pepperoni Pizza",
    quantity: 10,
    price: 50,
    snacksImg: pizza4,
    description:
      "Mozzarella cheese and thick-cut pepperoni equals pizza-perfection. We don’t mess with perfection, especially when it comes to pizza. Our classic pepperoni pie has 100% Real® Mozzarella cheese made from whole milk, and mouth-watering sauce made from vine-ripened, California tomatoes. But the star of the show is our thick-cut pepperoni. Have one baked just for you, in-store.",
    subCategory: "pizza",
    category: "food",
    rating: 4,
    newest: true,
  },

  // freshChilled
  {
    id: 1,
    foodName: "Italian Sub",
    quantity: 4,
    price: 50,
    foodImage: freshChilled5,
    description:
      "Ham, salami, pepperoni and provolone cheese on a sub sandwich roll.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "cheese",
    size: "",
    type: "sandwich",
  },

  {
    id: 2,
    foodName: "6 Turkey & Cheese Sub",
    quantity: 4,
    price: 50,
    foodImage: freshChilled10,
    description: "Turkey and American cheese on a sub sandwich roll.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "cheese",
    size: "",
    type: "sandwich",
  },

  {
    id: 3,
    foodName: "Chicken Caesar Pasta Salad",
    quantity: 4,
    price: 50,
    foodImage: freshChilled1,
    description:
      "Carbs can be our friend. Enjoy yummy penne pasta tossed with all white meat chicken, shredded parmesan, crunchy croutons, crisp Romaine, fresh tomatoes and Caesar dressing.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "chicken",
    size: "",
    type: "salad",
  },

  {
    id: 4,
    foodName: "Chicken Caesar Salad",
    quantity: 4,
    price: 50,
    foodImage: freshChilled2,
    description:
      "Why yes, we’ll take all white meat chicken, shredded parmesan, crunchy croutons, crisp Romaine, fresh tomatoes and rich Caesar dressing. Et tu, Brute?",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "chicken",
    size: "",
    type: "salad",
  },

  {
    id: 5,
    foodName: "Chicken Salad Sandwich",
    quantity: 4,
    price: 50,
    foodImage: freshChilled3,
    description:
      "Diced chicken breast combined with celery and reduced fat mayonnaise.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "chicken",
    size: "",
    type: "salad",
  },

  {
    id: 6,
    foodName: "Egg Salad Sandwich",
    quantity: 4,
    price: 50,
    foodImage: freshChilled4,
    description:
      "Lightly seasoned hard boiled eggs mixed with reduced fat mayonnaise and Dijon mustard.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "egg",
    size: "Entrée Salad",
    type: "sandwich",
  },

  {
    id: 7,
    foodName: "Fruit Blend Fruit Cup",
    quantity: 4,
    price: 50,
    foodImage: freshChilled6,
    description:
      "Sweet red grapes with cubes of pineapple, cantaloupe and honeydew. It’s hard to contain this much juiciness in one cup.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "",
    size: "cup",
    type: "fruit",
  },

  {
    id: 8,
    foodName: "Fruit Harvest Blend Fruit Cup",
    quantity: 4,
    price: 50,
    foodImage: freshChilled7,
    description:
      "Ready to snack healthy? This cup is packed with fresh strawberries, crisp apple slices and sweet red grapes. We think we can hear the crunch from here.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "",
    size: "cup",
    type: "fruit",
  },

  {
    id: 9,
    foodName: "Garden Side Salad",
    quantity: 4,
    price: 50,
    foodImage: freshChilled8,
    description:
      "Crisp, refreshing and not too heavy. Crunch into chopped Romaine, juicy grape tomatoes, matchstick carrots, red cabbage and a side of creamy ranch dressing.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "caesar Salad",
    size: "Side Salad",
    type: "",
  },

  {
    id: 9,
    foodName: "Strawberry Cup",
    quantity: 4,
    price: 50,
    foodImage: freshChilled9,
    description:
      "Grab some delicious fresh cut fruit.d a side of creamy ranch dressing.",
    subCategory: "freshChilled",
    category: "food",
    newest: true,
    rating: 4,
    flavor: "",
    size: "cup",
    type: "fruit",
  },
];

export default FoodApi;
