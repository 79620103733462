import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import deliveryman1 from "../../../../Assets/deliveryman2.png";
import deliveryman2 from "../../../../Assets/deliveryman3.png";
const Cauresol = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="py-10 md:py-24  bg-slate-200">
      <div className="max-w-screen-2xl mx-auto  bg-slate-200">
        <div className="pb-14 text-center md:text-start px-5 ">
          <h1 className="text-[24px] md:text-[44px] font-bold mb-3">
            Inside <span className="wellmary">WellMarry</span>
          </h1>
          <p className="md:text-[20px]">
            There's more to WellMarry than taquitos and Slurpee® drinks.
          </p>
        </div>
        <div>
          <Carousel
            responsive={responsive}
            autoPlaySpeed={1000}
            infinite={true}
            autoPlay={false}
            showDots={true}
            swipeable={true}
          >
            <div className="flex gap-5  bg-white mx-5 ">
              <div>
                <img
                  src="https://img.freepik.com/free-photo/pizza-delivery-man-counting-one_1368-3728.jpg?t=st=1676451236~exp=1676451836~hmac=cd974885fd809a20abb10da6a074c12345a54465d308ca1e376041098400e0d1"
                  alt=""
                  className="w-52  md:w-full"
                />
              </div>

              <div className="text-start w-52 md:w-full flex justify-center flex-col">
                <h1 className="text-2xl font-bold mb-3 wellmary">
                  Snacks for School
                </h1>
                <p className="mb-10">
                  All the food you want, when you want it.
                </p>
                <span className="font-bold text-lg">
                  <Link
                    to="#_"
                    class=" rounded px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
                  >
                    <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                    <span class="relative">Read More</span>
                  </Link>
                </span>
              </div>
            </div>
            <div className="flex gap-5  bg-white mx-5">
              <div>
                <img
                  src="https://img.freepik.com/free-photo/pizza-delivery-man-counting-one_1368-3728.jpg?t=st=1676451236~exp=1676451836~hmac=cd974885fd809a20abb10da6a074c12345a54465d308ca1e376041098400e0d1"
                  alt=""
                  className="w-52 md:w-full"
                />
              </div>
              <div className="text-start w-52 md:w-full flex justify-center flex-col">
                <h1 className="text-2xl font-bold mb-3 wellmary">
                  Snacks for School
                </h1>
                <p className="mb-10">
                  All the food you want, when you want it.
                </p>
                <span className="font-bold text-lg">
                  <Link
                    to="#_"
                    class=" rounded px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
                  >
                    <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                    <span class="relative">Read More</span>
                  </Link>
                </span>
              </div>
            </div>
            <div className="flex gap-5  bg-white mx-5">
              <div>
                <img
                  src="https://img.freepik.com/free-photo/pizza-delivery-man-counting-one_1368-3728.jpg?t=st=1676451236~exp=1676451836~hmac=cd974885fd809a20abb10da6a074c12345a54465d308ca1e376041098400e0d1"
                  alt=""
                  className="w-52 md:w-full"
                />
              </div>
              <div className="text-start w-52 md:w-full flex justify-center flex-col">
                <h1 className="text-2xl font-bold mb-3 wellmary">
                  Snacks for School
                </h1>
                <p className="mb-10">
                  All the food you want, when you want it.
                </p>
                <span className="font-bold text-lg">
                  <Link
                    to="#_"
                    class=" rounded px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
                  >
                    <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                    <span class="relative">Read More</span>
                  </Link>
                </span>
              </div>
            </div>
            <div className="flex gap-5  bg-white mx-5">
              <div>
                <img
                  src="https://img.freepik.com/free-photo/pizza-delivery-man-counting-one_1368-3728.jpg?t=st=1676451236~exp=1676451836~hmac=cd974885fd809a20abb10da6a074c12345a54465d308ca1e376041098400e0d1"
                  alt=""
                  className="w-52 md:w-full"
                />
              </div>
              <div className="text-start w-52 md:w-full flex justify-center flex-col">
                <h1 className="text-2xl font-bold mb-3 wellmary">
                  Snacks for School
                </h1>
                <p className="mb-10">
                  All the food you want, when you want it.
                </p>
                <span className="font-bold text-lg">
                  <Link
                    to="#_"
                    class=" rounded px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
                  >
                    <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                    <span class="relative">Read More</span>
                  </Link>
                </span>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Cauresol;
