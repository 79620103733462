import React from "react";
import AllFoodPages from "../ShopCollectionsShared/AllFoodPages/AllFoodPages";

const FeatureCollections = () => {
  return (
    <>
      <AllFoodPages fruitsCategoryName="Feature Collections" />
    </>
  );
};

export default FeatureCollections;
