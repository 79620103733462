import React, { useState } from "react";
import { ImLocation } from "react-icons/im";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const Location = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    feedback: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value);
    setData({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
  };
  const [modal, setModal] = useState(false);
  // React state to manage visibility
  // const [show, setShow] = useState(true);
  // function to toggle the boolean value
  // const toggleShow = () => {
  //   setShow(!show);
  // };

  const myFunction = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  return (
    <div className="bg-[#7dc24c] py-10 md:py-28">
      <div className="max-w-screen-2xl mx-auto">
        <div class="px-5 md:px- lg:px-80 relative">
          <form>
            <div class="styled-input  md:w-[500px] ">
              <input
                type="text"
                required
                className="pl-6 font-bold text-[#fff]  md:text-[24px]"
              />
              <label>Store Locator</label>
              <span></span>
            </div>
            <p className="absolute top-5 left-2 md:left-[305px]">
              <ImLocation size={30} className="text-[#fff]" />
            </p>
            <button className="absolute right-5 top-5 md:top-5 md:right-[5%] lg:right-[34%] md:hidden block">
              <FaLongArrowAltRight size={40} className="text-[#fff] " />
            </button>
            <div className=" justify-center px-2 bg-[#f9a61c] hover:bg-[#fdd00b] py-2 rounded text-white hidden md:block w-32">
              <button className="flex items-center text-black text-[24px] gap-3 ">
                Submit
                <FaLongArrowAltRight size={25} className="text-[#000] " />
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* feedback */}
      <div>
        {/* part 1 */}
        <div
          id="myDIV"
          className="px-8 py-3 flex items-center gap-8 rounded-md bg-[#f9a61c] hover:bg-[#fdd00b] fixed top-[50%] -right-[80px] rotate-90 z-[9999] cursor-pointer "
        >
          {!modal && (
            <>
              <p
                onClick={() => setModal((value) => !value)}
                className="  text-[#000]   text-[24px]"
              >
                Feedback{" "}
              </p>
            </>
          )}
          <button onClick={() => myFunction()}>
            <RxCross2 className="text-[#000] text-[24px] rounded " />
          </button>
        </div>

        {/* part2 */}
        {/* {show && (
          <div className="px-8 py-3 flex items-center gap-8 rounded-md bg-[#f9a61c] hover:bg-[#fdd00b] fixed top-[50%] -right-[80px] rotate-90 z-[9999] cursor-pointer ">
            <>
              <p className="  text-[#000]   text-[24px]">Feedback </p>
            </>

            <button onClick={toggleShow}>
              <RxCross2 className="text-[#000] text-[24px] rounded " />{" "}
              {buttonText}
            </button>
          </div>
        )} */}
        <div className="feedback ">
          {modal && (
            <div className="border-2 border-[#9ecc3a] p-5 bg-white shadow-lg drop-shadow-xl z-20 ">
              <form onSubmit={handleSubmit} className="">
                <button
                  className="close-btn-form"
                  onClick={() => setModal((value) => !value)}
                >
                  <RxCross2 className="text-[#7ac24d] text-[40px] font-bold rounded absolute top-3 right-3" />
                </button>
                <div className="flex justify-center py-12">
                  <img
                    src="https://i0.wp.com/wellmarry.com/wp-content/uploads/2022/12/Well-Marry-Logo-H-B.png?fit=7988%2C5780&ssl=1"
                    alt=""
                    className="w-36 pb-10"
                  ></img>
                </div>{" "}
                <div className="px-20">
                  <h1 className="font-bold text-2xl text-gray-700 pb-10 ">
                    We'd love your feedback!
                  </h1>
                </div>
                <div className="flex justify-center flex-col items-center">
                  {" "}
                  <div>
                    <input
                      onChange={(e) => handleChange(e)}
                      placeholder="Name"
                      name="name"
                      className="input-feedback w-96"
                    ></input>
                  </div>
                  <div>
                    <input
                      onChange={(e) => handleChange(e)}
                      className="input-feedback w-96"
                      placeholder="Email"
                      name="email"
                    ></input>{" "}
                  </div>
                  <div>
                    <textarea
                      onChange={(e) => handleChange(e)}
                      rows="6"
                      cols="50"
                      className="input-feedback border-0 p-4 w-96"
                      placeholder="Feedback"
                      name="feedback"
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-end gap-3 p-5">
                  <button
                    onClick={() => setModal((value) => !value)}
                    className="bg-red-700 text-white font-bold px-3 py-2 mt-5 rounded"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    name="submit"
                    className="bg-[#fdd00b] text-black font-bold px-3 py-2 mt-5 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <small className="text-end block px-5 ">
                powered by{" "}
                <a
                  href="https://weerodigital.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#0d4d98]"
                >
                  Weero Digital
                </a>{" "}
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
