import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CgCloseR } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { RiAccountCircleLine } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
const Header = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);
  let [open, setOpen] = useState(false);
  let [open1, setOpen1] = useState(false);
  let [openhum, setOpenHum] = useState(false);

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div className="shadow-md w-full sticky -top-2  z-[9999999] bg-white">
      <div className="max-w-screen-2xl mx-auto">
        <div className=" md:flex items-center py-4 md:px-10 px-2 text-[#252525]">
          <div className="flex justify-center md:justify-start">
            <div>
              <Link to="/">
                <span className="text-2xl font-bold text-[#54B55C]">
                  {" "}
                  WILLING
                </span>
              </Link>
            </div>
          </div>
          <div
            className="font-light text-2xl cursor-pointer flex items-center md:px-5 font-[Poppins] 
      text-[#252525] "
          >
            <ul
              className={`md:flex md:items-center md:justify-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0
           w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in navbar-links  ${
             open ? "top-16 " : "top-[-490px]"
           }`}
            >
              <li class="navbar-dropdown md:ml-5 text-[18px] 2xl:text-[22px] md:my-0 my-7">
                <Link
                  onClick={() => setOpen(!open)}
                  to="/foods"
                  className="active hover:text-[#54B55C] duration-300"
                >
                  Foods
                </Link>
                <div
                  className="dropdown1 font-light shadow drop-shadow-md top-[100%] md:top-[48px] md:text-[18px] w-[280px] md:w-[450px] "
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex gap-5 px-5 md:flex-row flex-col">
                    <div className="w-[250px]">
                      {" "}
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Bakery
                      </Link>
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Manchow Soup
                      </Link>
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Hot Soup
                      </Link>
                    </div>

                    <div className="w-[250px]">
                      {" "}
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Bakery
                      </Link>
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Manchow Soup
                      </Link>
                      <Link
                        to="#"
                        className=" hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Hot Soup
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li class="navbar-dropdown md:ml-5 text-[18px]  2xl:text-[22px] md:my-0 my-7 ">
                <Link
                  className="active hover:text-[#54B55C] duration-300"
                  to="#"
                >
                  Drinks
                </Link>
                <div
                  class="dropdown1 font-light shadow drop-shadow-md top-[100%] md:top-[48px] md:text-[18px] w-[280px] md:w-[450px]"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex gap-5 px-5 md:flex-row flex-col">
                    <div className="w-[250px]">
                      {" "}
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Bakery
                      </Link>
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Manchow Soup
                      </Link>
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Hot Soup
                      </Link>
                    </div>
                    <div className="w-[250px]">
                      {" "}
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Bakery
                      </Link>
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Manchow Soup
                      </Link>
                      <Link
                        to="#"
                        className="hover:text-[#54B55C] hover:tracking-wider duration-300"
                      >
                        Veg Hot Soup
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li class=" md:ml-5 text-[18px]       2xl:text-[22px] md:my-0 my-7 ">
                <Link
                  onClick={() => setOpen(!open)}
                  to="/KRewards"
                  className="active hover:text-[#54B55C] duration-300"
                >
                  Rewards
                </Link>
              </li>
              <li class=" md:ml-5 text-[18px]       2xl:text-[22px] md:my-0 my-7 ">
                <Link
                  onClick={() => setOpen(!open)}
                  to="/about"
                  className="active hover:text-[#54B55C] duration-300"
                >
                  About Us
                </Link>
              </li>
              <li class=" md:ml-5 text-[18px]       2xl:text-[22px] md:my-0 my-7  ">
                <Link
                  onClick={() => setOpen(!open)}
                  to="/shopLocation"
                  className="active hover:text-[#54B55C] duration-300"
                >
                  ShopLocation
                </Link>
              </li>
              <li class=" md:ml-5 text-[18px]       2xl:text-[22px] md:my-0 my-7  ">
                <Link
                  onClick={() => setOpen(!open)}
                  to="/shopCollections"
                  className="active hover:text-[#54B55C] duration-300"
                >
                  Collections
                </Link>
                {/* <div
                class="dropdown1 shadow-md drop-shadow-md  top-[100%] md:top-[250%] md:text-[24px] w-[280px] md:w-[650px]"
                onClick={() => setOpen(!open)}
              >
              <Link to="#" className="p-5">
                  Tomato Soup
                </Link>
              <Link to="#" className="p-5">
                  Veg Manchow Soup
                </Link>
              <Link to="#" className="p-5">
                  {" "}
                  Veg Hot Soup
                </Link>
              </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        onClick={() => setOpen(!open)}
        className="text-3xl absolute left-5 top-6 cursor-pointer lg:hidden"
      >
        {" "}
        {open ? (
          <CgCloseR className="md:ml-2" />
        ) : (
          <GiHamburgerMenu size={30} />
        )}
      </div>

      <div className="hidden 2xl:block">
        <div className="flex md:gap-10 gap-5 absolute top-[30px] right-[15%] md:right-[11%] md:top-5 cursor-pointer">
          <div className="  navbar-links  font-bold ">
            <li className="flex gap-2 navbar-dropdown ">
              <RiAccountCircleLine size={25} />

              <div class="dropdown1 font-light shadow drop-shadow-md w-[120px] md:w-[180px] top-[130%] md:top-12 md:text-[18px] right-[10%]">
                <Link
                  to="#"
                  className="px-5 hover:text-[#54B55C] duration-300  hover:tracking-wider"
                >
                  Name
                </Link>
                <Link
                  to="#"
                  className="px-5 hover:text-[#54B55C] duration-300 hover:tracking-wider"
                >
                  profile
                </Link>
              </div>
            </li>
          </div>
        </div>
      </div>

      {/* new */}
      <div className=" hidden 2xl:block">
        <input
          type="checkbox"
          class="toggler w-[50px] md:w-[219px] h-[60px] absolute top-[25%] right-[25%] md:right-[13%]  z-[9999999999] cursor-pointer"
        />
        <div class="hamburger absolute top-2 right-[25%] md:right-[13%] cursor-pointer">
          <div className="flex gap-1 justify-center items-center">
            {" "}
            <SlLocationPin size={20} className=" " />{" "}
            <span className=" text-xl ">Find a Store</span>
          </div>
        </div>

        <div class="menu z-[999999999999] ">
          <div className="active">
            <ul className="flex-col top-[0px] h-screen md:top-[65px] justify-center items-center bg-white gap-10 ">
              <li className="flex flex-col md:flex-row justify-center items-center">
                <div class="px-5  relative pt-14">
                  <h1 className="text-xl font-semibold lg:text-3xl uppercase">
                    Find A Store
                  </h1>
                  <form>
                    <div class="styled-input  md:w-[500px] ">
                      <input
                        type="text"
                        required
                        className="pl-6 font-bold text-[#fff]  md:text-[24px]"
                      />
                      <label className="text-black z-auto">Store Locator</label>
                      <span></span>
                    </div>

                    <div>
                      <div className="flex  justify-center mt-10 mb-5">
                        <label for="" className="text-2xl ">
                          Distance :
                        </label>
                      </div>
                      <div>
                        <div className="flex justify-center items-center gap-5 mb-3 pl-5">
                          <div>
                            <input
                              type="radio"
                              name="radio-7"
                              className="text-2xl mt-2"
                            />
                          </div>
                          <label for="" className="text-2xl">
                            20 Miles
                          </label>
                        </div>
                        <div className="flex justify-center items-center gap-5 mb-3 pl-5">
                          <div>
                            <input
                              type="radio"
                              name="radio-7"
                              className="text-2xl mt-2"
                            />
                          </div>
                          <label for="" className="text-2xl">
                            30 Miles
                          </label>
                        </div>
                        <div className="flex justify-center items-center gap-5 pl-5">
                          <div>
                            <input
                              type="radio"
                              name="radio-7"
                              className="text-2xl mt-2"
                            />
                          </div>
                          <label for="" className="text-2xl">
                            10 Miles
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </li>

              <li className="flex flex-col md:flex-row gap-5">
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-gray-700 px-5 py-1 text-white rounded-xl"
                  >
                    Submit
                  </button>
                </div>
                <h2 className="my-3 text-center text-lg font-bold">OR</h2>
                <button
                  type="submit"
                  className="px-3 py-1 bg-gray-500 rounded text-white"
                >
                  use of my location
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
