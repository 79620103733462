import React from "react";
import foodApi from "../../../FakeApi/FoodApi";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Star from "../../../ShopCollectionsPage/Star/Star";
const FoodSingleProductDetails = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);
  const [filteredProducts, setFilteredProducts] = useState(foodApi);
  console.log(foodApi);
  let { FoodProductId } = useParams();

  console.log(FoodProductId);

  const product = foodApi.find(
    (product) => String(product.id) === FoodProductId
  );

  if (!product) {
    return "Sorry, no matching product found.";
  }
  const {
    foodImage,
    quantity,
    price,
    productName,
    description,
    rating,
    reveiw,
    subCategory,
  } = product;
  const filteredItems = foodApi.filter((items) => {
    return (
      // String(items._id) === FoodProductId ||
      items.subCategory === subCategory
      // items.tag === "PartyDresses"
    );
  });
  // console.log(filteredItems);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const shuffledNames = shuffleArray(filteredItems);
  console.log(shuffledNames);
  return (
    <div>
      <div className="max-w-screen-2xl mx-auto py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
          <div className="flex justify-center ">
            <img
              className={` w-72      ${
                quantity === 0 ? "grayscale blur-sm" : ""
              }`}
              src={foodImage}
              alt=""
            />
          </div>
          <div className="flex flex-col ">
            <p className="text-2xl font-bold">{productName}</p>
            <p className="mb-5 text-2xl font-bold text-gray-900">
              {" "}
              Price: $ {price}
            </p>
            <div class="flex  items-center gap-3 ">
              <Star rating={rating} reveiw={reveiw}></Star>{" "}
            </div>
            <p className="text-[20px]">
              <span className="font-bold text-2xl">Description:</span>{" "}
              {description}
            </p>
            <div>
              <button class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-56 flex items-center justify-center">
                Add To Cart{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <section className="py-14">
          <h1 className="text-[26px] text-center py-14">Related Products</h1>
          <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
            {shuffledNames.slice(0, 4).map((foodData) => {
              return (
                <Link key={foodData.id} to={`/foods/bakery/${foodData.id}`}>
                  <div className="border gap-2 group">
                    <div className="flex justify-center mb-4 rounded shadow overflow-hidden">
                      <img
                        className={`w-56 h-56   group-hover:scale-110 duration-300   ${
                          foodData.quantity === 0 ? "grayscale blur-sm" : ""
                        }`}
                        src={foodData.foodImage}
                        alt="Person"
                      />
                    </div>
                    <div className="flex flex-col sm:text-center">
                      <p className="text-lg font-bold px-5 ">
                        {foodData.productName}
                      </p>
                      <p className="mb-5 text-md font-bold text-gray-900 ">
                        {foodData.price} $
                      </p>
                    </div>

                    <div>
                      <button class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center">
                        Add To Cart{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FoodSingleProductDetails;
