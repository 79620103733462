// thsirt
import tshirt1 from "../../Assets/shopkcollections/newArrivalPic/update/tee biskit.png";
import tshirt2 from "../../Assets/shopkcollections/newArrivalPic/update/tee shirt green.png";
import tshirt3 from "../../Assets/shopkcollections/newArrivalPic/update/tee shirt white pro.png";
import tshirt4 from "../../Assets/shopkcollections/newArrivalPic/update/tee shirt white.png";
import tshirt5 from "../../Assets/shopkcollections/newArrivalPic/update/tee white pro.png";
import tshirt6 from "../../Assets/shopkcollections/newArrivalPic/update/tee white.png";
import tshirt7 from "../../Assets/shopkcollections/newArrivalPic/update/teeShirt black pro.png";

//cap
import cap1 from "../../Assets/shopkcollections/newArrivalPic/update/cap accent.png";
import cap2 from "../../Assets/shopkcollections/newArrivalPic/update/cap black (2).png";
import cap3 from "../../Assets/shopkcollections/newArrivalPic/update/cap black.png";
import cap4 from "../../Assets/shopkcollections/newArrivalPic/update/cap light.png";

//pant
import pant5 from "../../Assets/shopkcollections/newArrivalPic/pant2.jpeg";
import pant4 from "../../Assets/shopkcollections/newArrivalPic/pant4.jpg";
import pant1 from "../../Assets/shopkcollections/newArrivalPic/update/pant green.png";
import pant2 from "../../Assets/shopkcollections/newArrivalPic/pant3.jpeg";
import pant3 from "../../Assets/shopkcollections/newArrivalPic/update/pant red.png";

// show
import show4 from "../../Assets/shopkcollections/newArrivalPic/show4.jpg";
import show2 from "../../Assets/shopkcollections/newArrivalPic/show2.jpeg";
import show3 from "../../Assets/shopkcollections/newArrivalPic/show3.jpg";
import show1 from "../../Assets/shopkcollections/newArrivalPic/show1.jpeg";
import show5 from "../../Assets/shopkcollections/newArrivalPic/show5.jpeg";
import show7 from "../../Assets/shopkcollections/newArrivalPic/show7.jpeg";
import show6 from "../../Assets/shopkcollections/newArrivalPic/update/cap accent.png";

// dress
import dres1 from "../../Assets/shopkcollections/newArrivalPic/pant1.jpg";
import dres3 from "../../Assets/shopkcollections/newArrivalPic/weman1.jpg";
import dres7 from "../../Assets/shopkcollections/newArrivalPic/shitr1.jpeg";
import dres5 from "../../Assets/shopkcollections/newArrivalPic/weman2.jpg";
import dres4 from "../../Assets/shopkcollections/newArrivalPic/shitr3.jpg";
import dres2 from "../../Assets/shopkcollections/newArrivalPic/shitr5.jpg";
import dres6 from "../../Assets/shopkcollections/newArrivalPic/weman3.jpeg";

const newArrival = [
  //shirt (1-7 = 7)
  {
    _id: 1,
    productName: "Tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt1,
    description:
      "Rep the new Slurpee® logo in style with these bright neon tees! Details & Care,100% combed ring-spun cotton,Mid-weight,Ribbed neckline,Double-stitched hems with side seams",
    subCategory: "shirt",
    size: "S",
    reveiw: 55,
    newest: true,
    rating: 2.6,
    color: "",
  },

  {
    _id: 2,
    productName: "Tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt2,
    description:
      "Shine bright with this sparkly S neon Slurpee® tee! Details & Care,100% combed ring-spun cotton,Mid-weight,Ribbed neckline,Double-stitched hems with side seams",
    subCategory: "shirt",
    size: "M",
    reveiw: 40,
    newest: true,
    rating: 2.6,
    color: "",
  },

  {
    _id: 3,
    productName: "tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt4,
    description:
      "Unleash your inner brainfreeze™ with this mind-bending Slurpee® shirt. Featuring a psychedelic neon graphic, this tee is a must-have for fans of far-out fashion. Details & Care,100% combed ring-spun cotton,Mid-weight,Ribbed neckline,Double-stitched hems with side seams",
    subCategory: "shirt",
    size: "L",
    reveiw: 40,
    newest: true,
    rating: 4.4,
  },

  {
    _id: 4,
    productName: "Tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt6,
    description:
      "Kick it 90's style with this pink and blue Slurpee® logo tank top! 100%combed cotton, Lightweight 4.4 oz/yd,Sleeveless tee, raw armhole edges",
    subCategory: "shirt",
    size: "XL",
    reveiw: 70,
    newest: true,
    rating: 5,
    color: "",
  },

  {
    _id: 5,
    productName: "Tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt5,
    description:
      "It's always a good day for a Slurpee® drink! Beat the heat in this neon print tank top. 100%combed cotton, Lightweight 4.4 oz/yd,Sleeveless tee, raw armhole edges",
    subCategory: "shirt",
    size: "S",
    reveiw: 80,
    newest: true,
    rating: 4.8,
  },

  {
    _id: 6,
    productName: "Tshirt",
    quantity: 5,
    price: 25,
    productImg: tshirt3,
    description:
      "Shine bright with this sparkly neon Slurpee® long sleeve! Beat the heat in this neon print tank top. 100%combed cotton, Lightweight 4.4 oz/yd,Sleeveless tee, raw armhole edges",
    subCategory: "shirt",
    size: "M",
    reveiw: 90,
    newest: true,
    rating: 5,
    color: "",
  },

  {
    _id: 7,
    productName: "TShirt",
    quantity: 5,
    price: 27,
    productImg: tshirt7,
    description:
      "Stay warm with our classic Slurpee® swirl long sleeve. Beat the heat in this neon print tank top. 100%combed cotton, Lightweight 4.4 oz/yd,Sleeveless tee, raw armhole edges",
    subCategory: "shirt",
    size: "L",

    reveiw: 30,
    newest: true,
    rating: 3,
    color: "",
  },

  //cap (8- 11= 4)
  {
    _id: 8,
    productName: " Hat",
    quantity: 5,
    price: 27,
    productImg: cap3,
    description:
      "Top off your vibey look with our Slurpee® Fuzzy Bucket Hat! Product Details:Fuzzy plush knit bucket hat featuring a wide brim 100% Polyester One size fits Most 22.8 Circumference",
    subCategory: "cap",
    size: "S",
    reveiw: 80,
    newest: true,
    rating: 5,
    color: "",
  },

  {
    _id: 9,
    productName: "Cap Pin",
    quantity: 5,
    price: 27,
    productImg: cap2,
    description:
      "Show your love for Slurpee® drinks with our new logo enamel pin. Perfect pins for backpacks and denim jackets Details: Hard Enamel Custom Pin 1.7 wide Rubber backer",
    subCategory: "cap",
    size: "M",
    reveiw: 20,
    newest: true,
    rating: 2.5,
    color: "",
  },

  {
    _id: 10,
    productName: " Cord Cap",
    quantity: 5,
    price: 27,
    productImg: cap1,
    description:
      "Uncomplicate your wardrobe with this  hat that says it all - because who needs complications when you have convenience? Product Details: Embroidered art Interior custom taping 5-Panel Mid Crown Flat Visor Buckle Back Closure One Size Fits Most 100% Cotton",
    subCategory: "cap",
    size: "L",
    reveiw: 20,
    newest: true,
    rating: 2.2,
    color: "",
  },
  {
    _id: 11,
    productName: " Convenience Hat",
    quantity: 5,
    price: 25,
    productImg: cap4,
    description:
      "Keep it cool and convenient with the 'Your Favorite Convenience Hat,' perfect for those looking for an easy way to elevate any outfit! Product Details: Embroidered art Interior custom taping 5-Panel Mid Crown Flat Visor Buckle Back Closure One Size Fits Most 100% Cotton",
    subCategory: "cap",
    size: "S",
    reveiw: 40,
    newest: true,
    rating: 4,
    color: "",
  },
  //pant (12-16 = 5)
  {
    _id: 12,
    productName: " Cargo Pants",
    quantity: 5,
    price: 45,
    productImg: pant1,
    description:
      "The most  pants you've probably ever seen, these heavyweight bottoms feature an 11 design with 7 pockets.Product Details: Runs big - we recommend going 1 size down from your normal size. Heavyweight denim pants with seven pockets.",
    subCategory: "pant",
    size: "S",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 13,
    productName: " Jogger",
    quantity: 5,
    price: 45,
    productImg: pant4,
    description:
      "Stay cozy and in style with our green  sweatpants, perfect for those who love to keep it comfortable and convenient. Pair it with our matching hoodie! Product Details: 80/20 Cotton/Poly Combed Ring-Spun Cotton Premium Fleece Fabric 9.5- Ounce • 325 GSM Super-Soft • Heavyweight • Relax Fit Elastic Waistband and Bottom Hem 2 Side Pockets and 1 Back Pocket",
    subCategory: "pant",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 14,
    productName: " JEANS FOR MEN",
    quantity: 5,
    price: 255,
    productImg: pant2,
    description:
      "Product details of EXPORT Quality denim JEANS FOR MEN Main Material: Denim Gender: Men, Color: Blue ,Fade Washed Waist: Mid-rise",
    subCategory: "pant",
    size: "L",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 15,
    productName: " Pant for Men",
    quantity: 5,
    price: 265,
    productImg: pant3,
    description:
      "Product details of Twill Gabardine Pant for Men Brand: DUDE STYLE Size : 28,30,32,34,36 Product Type: Jeans Main Material: Chinos Gender: Male Stretchable : Yes(10%) Wash and Care: Machine Wash",
    subCategory: "pant",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 16,
    productName: " Slim-Fit",
    quantity: 5,
    price: 290,
    productImg: pant5,
    description:
      "Stay cozy and in style with our green  sweatpants, perfect for those who love to keep it comfortable and convenient. Pair it with our matching hoodie! Product Details: 80/20 Cotton/Poly Combed Ring-Spun Cotton Premium Fleece Fabric 9.5- Ounce • 325 GSM Super-Soft • Heavyweight • Relax Fit Elastic Waistband and Bottom Hem 2 Side Pockets and 1 Back Pocket",
    subCategory: "pant",
    size: "S",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  //shows (17-23 = 7)
  {
    _id: 17,
    productName: "Shoes Sneakers",
    quantity: 5,
    price: 14.99,
    productImg: show2,
    description:
      "DC Gaveler Details Catch some air with these comfortable DC® skate shoes:durable synthetic upper lace-up front closure low-top for freedom of motion foam padded tongue and collar for comfort and support thick rubber outsole for traction decorative speckled pattern on the outsole",
    subCategory: "shows",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 18,
    productName: "Shoes Sneakers",
    quantity: 5,
    price: 14.99,
    productImg: show3,
    description:
      "DC Gaveler Details Catch some air with these comfortable DC® skate shoes:durable synthetic upper lace-up front closure low-top for freedom of motion foam padded tongue and collar for comfort and support thick rubber outsole for traction decorative speckled pattern on the outsole",
    subCategory: "shows",
    size: "L",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 19,
    productName: "e Sneaker",
    quantity: 5,
    price: 49,
    productImg: show4,
    description:
      "Shipping & Import Fees Deposit to Bangladesh Details Size: Color: Rainbow Pride Size Chart 100% PU_Leather Rubber sole Water Resistance Level: Water Resistant Water Resistance Level: Water Resistant Coordinated bold rainbow ombre laces. Bottom soles reveal fun, dramatic rainbow color block detailing.Removable cushioned insole for padded comfort.Quick to clean with a wet wipe",
    subCategory: "shows",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 20,
    productName: " Sneaker",
    quantity: 5,
    price: 909,
    productImg: show6,
    description:
      "Reebok is an inspirational athletic lifestyle company that has always lived at the intersection of sport and style.​ Their fitness herisubCategorye gives them credibility in the sports space while their foundation in Classics gives them a foothold in lifestyle.​ Reebok makes products that inspire human movement—and they advocate for human rights for all. Reebok is a brand that gets people moving, that encourages sport and movement.  Reebok is here to inspire you to be your best self. Reebok is guided by the fundamental belief that LIFE IS NOT A SPECTATOR SPORT. ​No matter who you are, where you came from, how you move, or what you look like –​ everyone deserves to move freely.",
    subCategory: "shows",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 21,
    productName: "Sneaker",
    quantity: 5,
    price: 809,
    productImg: show7,
    description: "Classic Leather Comfort Sneaker with Lightweight Cushioning",
    subCategory: "shows",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 22,
    productName: " Sneaker",
    quantity: 5,
    price: 33,
    productImg: show5,
    description:
      "The Summer, the Rizzo Sneaker by Katy Perry, Lace-up in Printed Patent",
    subCategory: "shows",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 23,
    productName: " Sneaker",
    quantity: 5,
    price: 33,
    productImg: show1,
    description:
      "Embracing ECCO's core values, the Soft 7 sneaker is a celebration of Scandinavian design values with an understated contemporary twist",
    subCategory: "shows",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  //dress (24-30 = 7)

  {
    _id: 24,
    productName: "Men's Dress Shirt ",
    quantity: 5,
    price: 79,
    productImg: dres1,
    description: "Calvin Klein long sleeve all cotton non iron dress shirt.",
    subCategory: "shirt",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 25,
    productName: " Dress Shirts Short ",
    quantity: 5,
    price: 79,
    productImg: dres2,
    description:
      "A timeless button-down design and solid color gives this short-sleeve oxford shirt an easy work-to-weekend vibe. It's cut from washed cotton for ultimate softness and features a wrinkle-free finish and traditional details that add styling versatility.",
    subCategory: "shirt",
    size: "S",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 26,
    productName: " Shirt",
    quantity: 5,
    price: 79,
    productImg: dres3,
    description:
      "Designed to meet the needs of active anglers, this shirt is made of soft 100% cotton poplin for an easy fit.Made to fish with plenty of pockets and a rod holder to help free up your hands while you tie your next lure.A mesh-lined vented back keeps things breezy and comfortable, while a utility loop keeps gear handy.Columbia offers this short sleeve shirt in multiple colors and sizes.Extended sizing available.Relaxed Fit.To ensure the size you choose is right, utilize our sizing chart and the following measurement instructions: To determine the neck size, measure at the base or thickest part of your neck.For the chest, measure at the fullest part of the chest, under the armpits, and over the shoulder blades, keeping the tape measure firm and level.",
    subCategory: "shirt",
    size: "XL",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 27,
    productName: " Shirt",
    quantity: 5,
    price: 54,
    productImg: dres4,
    description:
      "The next generation of tactical shirt is here. Built with revolutionary NE stretch fabric, the Men's Kinetic Short Sleeve Shirt is designed to keep you moving forward, ahead, in pursuit of your goals. The perfect complement to the Kinetic Pant and Short.",
    subCategory: "shirt",
    size: "L",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 28,
    productName: " Evening Dress",
    quantity: 5,
    price: 54,
    productImg: dres5,
    description:
      "Whether you're attending a wedding, cocktail party, or formal event, the Bell Sleeve A-line Dress is the perfect outfit for any occasion.Available in plus sizes, this dress is designed to flatter every shape and size, ensuring that you feel confident and beautiful wherever you go.",
    subCategory: "Accessories",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 29,
    productName: " Pique Polo Dress",
    quantity: 5,
    price: 128,
    productImg: dres6,
    description:
      "Made from soft stretch cotton pique, this classic preppy polo dress is effortlessly chic yet casual.",
    subCategory: "Accessories",
    size: "S",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },

  {
    _id: 30,
    productName: "Weekend Dress",
    quantity: 5,
    price: 64,
    productImg: dres7,
    description:
      "Soft, lofty French terry knit is 97% Pima organic cotton, 3% shapekeeping spandex Ethically made in a fair trade cooperative using ecologically gentle Oeko-tex certified dyes free of harmful chemicals",
    subCategory: "Accessories",
    size: "M",
    reveiw: 60,
    newest: true,
    rating: 4,
    color: "",
  },
  {
    _id: 31,
    productName: "Tshirt",
    quantity: 5,
    size: "S",
    price: 25,
    productImg: tshirt1,
    description:
      "Rep the new Slurpee® logo in style with these bright neon tees! Details & Care,100% combed ring-spun cotton,Mid-weight,Ribbed neckline,Double-stitched hems with side seams",
    subCategory: "New Arrivals",
    size: "L",
    reveiw: 55,
    newest: true,
    rating: 2.6,
    color: "",
  },
  {
    _id: 32,
    productName: "TShirt",
    quantity: 5,
    size: "M",
    price: 27,
    productImg: tshirt7,
    description:
      "Stay warm with our classic Slurpee® swirl long sleeve. Beat the heat in this neon print tank top. 100%combed cotton, Lightweight 4.4 oz/yd,Sleeveless tee, raw armhole edges",
    subCategory: "New Arrivals",
    reveiw: 30,
    newest: true,
    rating: 3,
    color: "",
  },
  {
    _id: 33,
    productName: " Hat",
    quantity: 5,
    price: 27,
    productImg: cap3,
    description:
      "Top off your vibey look with our Slurpee® Fuzzy Bucket Hat! Product Details:Fuzzy plush knit bucket hat featuring a wide brim 100% Polyester One size fits Most 22.8 Circumference",
    subCategory: "New Arrivals",
    size: "XL",
    reveiw: 80,
    newest: true,
    rating: 5,
    color: "",
  },
  {
    _id: 34,
    productName: "Cargo Pants",
    quantity: 5,
    price: 45,
    productImg: pant1,
    description:
      "The most  pants you've probably ever seen, these heavyweight bottoms feature an 11 design with 7 pockets.Product Details: Runs big - we recommend going 1 size down from your normal size. Heavyweight denim pants with seven pockets.",
    subCategory: "New Arrivals",
    size: "XL",
    reveiw: 45,
    newest: true,
    rating: 3.8,
    color: "",
  },
  {
    _id: 35,
    productName: "Jogger",
    quantity: 5,
    price: 45,
    productImg: pant4,
    description:
      "Stay cozy and in style with our green  sweatpants, perfect for those who love to keep it comfortable and convenient. Pair it with our matching hoodie! Product Details: 80/20 Cotton/Poly Combed Ring-Spun Cotton Premium Fleece Fabric 9.5- Ounce • 325 GSM Super-Soft • Heavyweight • Relax Fit Elastic Waistband and Bottom Hem 2 Side Pockets and 1 Back Pocket",
    subCategory: "New Arrivals",
    size: "L",
    reveiw: 30,
    newest: true,
    rating: 3.5,
    color: "",
  },
  {
    _id: 36,
    productName: " Evening Dress",
    quantity: 5,
    price: 54,
    productImg: dres5,
    description:
      "Whether you're attending a wedding, cocktail party, or formal event, the Bell Sleeve A-line Dress is the perfect outfit for any occasion.Available in plus sizes, this dress is designed to flatter every shape and size, ensuring that you feel confident and beautiful wherever you go.",
    subCategory: "New Arrivals",
    size: "S",
    reveiw: 50,
    newest: true,
    rating: 4.5,
    color: "",
  },

  {
    _id: 37,
    productName: " Pique Polo Dress",
    quantity: 5,
    price: 128,
    productImg: dres6,
    description:
      "Made from soft stretch cotton pique, this classic preppy polo dress is effortlessly chic yet casual.",
    subCategory: "New Arrivals",
    size: "M",
    reveiw: 90,
    newest: true,
    rating: 5,
    color: "",
  },
  {
    _id: 38,
    productName: " Sneaker",
    quantity: 5,
    price: 909,
    productImg: show6,
    description:
      "Reebok is an inspirational athletic lifestyle company that has always lived at the intersection of sport and style.​ Their fitness herisubCategorye gives them credibility in the sports space while their foundation in Classics gives them a foothold in lifestyle.​ Reebok makes products that inspire human movement—and they advocate for human rights for all. Reebok is a brand that gets people moving, that encourages sport and movement.  Reebok is here to inspire you to be your best self. Reebok is guided by the fundamental belief that LIFE IS NOT A SPECTATOR SPORT. ​No matter who you are, where you came from, how you move, or what you look like –​ everyone deserves to move freely.",
    subCategory: "New Arrivals",
    size: "XL",
    reveiw: 70,
    newest: true,
    rating: 4.5,
    color: "",
  },
  {
    _id: 39,
    productName: "Sneaker",
    quantity: 5,
    price: 809,
    productImg: show7,
    description: "Classic Leather Comfort Sneaker with Lightweight Cushioning",
    subCategory: "New Arrivals",
    size: "M",
    reveiw: 30,
    newest: true,
    rating: 3.5,
    color: "",
  },

  {
    _id: 40,
    productName: "Sneaker",
    quantity: 5,
    price: 33,
    productImg: show5,
    description:
      "The Summer, the Rizzo Sneaker by Katy Perry, Lace-up in Printed Patent",
    subCategory: "New Arrivals",
    size: "M",
    reveiw: 80,
    newest: true,
    rating: 5,
    color: "",
  },
];

export default newArrival;
