import axios from "axios";
import React, { useEffect, useState } from "react";
import AllFoodPages from "../../../ShopCollectionsPage/ShopCollectionsShared/AllFoodPages/AllFoodPages";
import FoodPage from "../FoodPage/FoodPage";

const Bakery = () => {
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   axios.get("http://localhost:3009/data").then((res) => {
  //     setData(res.data);
  //   });
  // }, []);
  return (
    <div className="max-w-screen-2xl mx-auto">
      <FoodPage FoodCategory="bakery" />
    </div>
  );
};

export default Bakery;
