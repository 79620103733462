import React from "react";
import {
  FaFacebook,
  FaGooglePlus,
  FaInstagram,
  FaInstagramSquare,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { FiSend } from "react-icons/fi";

const Footer = () => {
  return (
    <div className=" bg-gray-300 ">
      <footer class="footer-section max-w-screen-2xl mx-auto">
        <div class="container ">
          <div class="footer-cta pt-5 pb-5">
            <div class="grid grid-cols-1 md:grid-cols-3 md:gap-5 text-center md:text-start justify-items-center items-center">
              <div class=" mb-3">
                <div class="single-cta">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="cta-text">
                    <h4>Find us</h4>
                    <span className="text-[#4fb153]">
                      1010 Avenue, sw 54321, chandigarh
                    </span>
                  </div>
                </div>
              </div>
              <div class=" mb-3">
                <div class="single-cta">
                  <i class="fas fa-phone"></i>
                  <div class="cta-text">
                    <h4>Call us</h4>
                    <span>9876543210 0</span>
                  </div>
                </div>
              </div>
              <div class=" mb-3">
                <div class="single-cta">
                  <i class="far fa-envelope-open"></i>
                  <div class="cta-text">
                    <h4>Mail us</h4>
                    <span>mail@info.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-content pt-5 pb-5 ">
            <div class="grid grid-cols-1 md:grid-cols-3 md:gap-10 text-center md:text-start">
              <div class=" mb-5">
                <div class="footer-widget flex flex-col   justify-center  items-center">
                  <div class="footer-logo text-2xl font-bold text-[#54B55C]">
                    <a href="index.html">WILLING</a>
                  </div>
                  <div class="footer-text  px-10">
                    <p>
                      Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                      sed do eiusmod tempor incididuntut consec tetur
                      adipisicing elit,Lorem ipsum dolor sit amet.
                    </p>
                  </div>
                  <div class="pb-6">
                    <span className="text-3xl text-black">Follow us</span>
                  </div>
                  <div class="social-media flex">
                    <a href="#">
                      <FaFacebook class=" fa-facebook i" />
                    </a>
                    <a href="#">
                      <FaTwitter class=" fa-twitter i" />
                    </a>
                    <a href="#">
                      <FaGooglePlus class=" fa-github i" />
                    </a>
                    <a href="#">
                      <FaInstagramSquare className="fa-instagram i" />
                    </a>
                    <a href="#">
                      <FaLinkedinIn className="fa-linkedin-in i" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="  mb-3">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">about</a>
                    </li>
                    <li>
                      <a href="#">services</a>
                    </li>
                    <li>
                      <a href="#">portfolio</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <a href="#">About us</a>
                    </li>
                    <li>
                      <a href="#">Our Services</a>
                    </li>
                    <li>
                      <a href="#">Expert Team</a>
                    </li>
                    <li>
                      <a href="#">Contact us</a>
                    </li>
                    <li>
                      <a href="#">Latest News</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="  mb-5">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div class="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div class="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <FiSend size={30} />
                      </button>
                    </form>
                  </div>
                </div>
                <div class="  my-5">
                  <div class="footer-widget">
                    <div class="footer-widget-heading">
                      <h3>Address</h3>
                    </div>
                    <div class="footer-text mb-25">
                      <p className="text-[17px] capitalize">
                        Uttar-Pelayed, Telihaty, Banani Dhaka-1207, Bangladesh
                      </p>
                      <p className="text-[22px] mt-3 font-bold">
                        {" "}
                        <span className="text-[#bb4f32] font-bold">
                          Hotline:
                        </span>{" "}
                        +880 000 000 000
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section>
        <div class="copyright-area attribution">
          <div class="container max-w-screen-2xl mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-10 px-10">
              <div class=" text-center md:text-start ">
                <div class="copyright-text flex items-center justify-center md:justify-start gap-5 px-5 md:px-0">
                  <p>
                    Copyright &copy; 2018, All Right Reserved{" "}
                    <span className="wellmary ">WILLING</span>{" "}
                    <span>
                      {" "}
                      <a href="mailto:">Design By Weeopka</a>
                    </span>
                  </p>
                </div>
              </div>
              <div class="text-center flex items-center justify-center md:justify-end px-5 md:px-0  ">
                <div class="footer-menu ">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
