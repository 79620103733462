import React from "react";
import { Link } from "react-router-dom";

const GetApp = () => {
  return (
    <div className="bg-gradient-to-r from-[#4eaf4f]  to-[#55b65e] py-10 md:py-5 mx-2 md:mx-0">
      <div className="grid grid-cols-1 md:grid-cols-4  md:gap-10 max-w-screen-2xl mx-auto">
        <div className="flex md:items-center justify-center mb-10 md:mb-0">
          <Link to="/">
            <span className="text-2xl font-bold text-white"> WILLING</span>
          </Link>
        </div>
        <div className="text-[#fff] md:mt-10 col-span-2  md:px-0 mb-8 md:mb-0">
          <h1 className="text-center font-bold text-[26px] md:text-[28px] mb-3">
            FREE Pizza on Your First Delivery Order
          </h1>
          <p className="text-center md:text-[18px] mb-2 ">
            <span className=" ">
              {" "}
              Enjoy 24/7 delivery and get a FREE large pizza on your first 7NOW®
            </span>
            delivery order with <span className="font-bold"> code: PIZZA</span>
          </p>
        </div>
        <div className="flex  justify-center mx-2 md:mx-0 md:px-0 md:justify-start items-center md:mt-10">
          <span className=" bg-white rounded-full hover:text-[#000000] hover:bg-[#fdc90e]  px-5 py-3 text-[#000000]  md:text-[20px]">
            <Link>Get The App</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default GetApp;
