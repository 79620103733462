import React from "react";
import cartFoodPic from "../../../Assets/Food/Breakfast/breakfast Empanadas.png";

import threeSter from "../../../Assets/Icon/Rating/threeSter.png";
const ShopCollectionProductSummery = () => {
  return (
    <>
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div>
            <img
              class="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src={cartFoodPic}
              alt=""
            />
          </div>

          <div class="flex flex-col justify-center">
            <div class="max-w-xl mb-6">
              <h2 class="max-w-lg mb-6 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none">
                Product Summery
              </h2>
              <div className="flex gap-3">
                <span className="font-bold">3.9</span>
                <img className="w-28 h-6" src={threeSter} alt="" />
                <a
                  href="#"
                  className="cursor-pointer text-sky-600 underline
                "
                >
                  See all 572 review
                </a>
              </div>
              <div className="py-4">
                <p>Quantity</p>
                <div className="flex gap-2 pt-1">
                  <button className="border px-6 py-1 hover:bg-green-500 hover:text-white duration-300">
                    1
                  </button>
                  <button className="border px-6 py-1 hover:bg-green-500 hover:text-white duration-300">
                    2
                  </button>
                  <button className="border px-6 py-1 hover:bg-green-500 hover:text-white duration-300">
                    3
                  </button>
                  <button className="border px-6 py-1 hover:bg-green-500 hover:text-white duration-300">
                    4
                  </button>
                </div>
              </div>

              <button className="bg-green-500 hover:bg-green-600 w-[80%] py-2 text-white font-bold ">
                Add To Cart
              </button>

              <div className="py-8">
                <p>Description</p>
                <p class="text-normal text-gray-700 md:text-lg">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae. explicabo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopCollectionProductSummery;
