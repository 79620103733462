import React from "react";
import ReactPlayer from "react-player/lazy";
import video from "../.././../../Assets/foodVideo/video1.mp4";
import NewArrival from "../../../FakeApi/newArrivalApi";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { Link } from "react-router-dom";

const ShopCollectionVideo = () => {
  return (
    <div>
      <section className="max-w-screen-2xl mx-auto">
        <div className="py-20">
          <h1 className="text-[35px] text-center mb-5">New Arrival</h1>
          <div>
            <Carousel
              plugins={[
                "centered",
                "infinite",
                "arrows",
                "autoplay",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 5,
                  },
                },
              ]}
              breakpoints={{
                640: {
                  plugins: [
                    "centered",
                    "infinite",
                    "arrows",
                    {
                      resolve: slidesToShowPlugin,
                      options: {
                        numberOfSlides: 1,
                      },
                    },
                  ],
                },
                900: {
                  plugins: [
                    "centered",
                    "infinite",
                    "arrows",
                    {
                      resolve: slidesToShowPlugin,
                      options: {
                        numberOfSlides: 5,
                      },
                    },
                  ],
                },
              }}
            >
              {NewArrival.map((foodData) => {
                return (
                  <Link
                    key={foodData._id}
                    to={`/shopCollections/newArrivals/${foodData._id}`}
                  >
                    <div className="border gap-2 group">
                      <div className="flex justify-center mb-4 rounded shadow overflow-hidden">
                        <img
                          className={`w-56 h-56   group-hover:scale-110 duration-300   ${
                            foodData.quantity === 0 ? "grayscale blur-sm" : ""
                          }`}
                          src={foodData.productImg}
                          alt="Person"
                        />
                      </div>
                      <div className="flex flex-col sm:text-center">
                        <p className="text-lg font-bold px-5 ">
                          {foodData.productName}
                        </p>
                        <p className="mb-5 text-md font-bold text-gray-900 ">
                          {foodData.price} $
                        </p>
                      </div>

                      <div>
                        <button class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center">
                          Add To Cart{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
      <section>
        <div className="player-wrapper mt-10">
          <ReactPlayer
            loop={true}
            playing={true}
            volume={null}
            className="react-player"
            url={video}
            width="100%"
            height="100%"
          />
        </div>
      </section>
    </div>
  );
};

export default ShopCollectionVideo;
