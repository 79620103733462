import React from "react";
import locationBannerDesk from "../../../../Assets/shopLocation/location.jpeg";
import loction from "../../../../Assets/shopLocation/shopbanner.jpeg";
import banani from "../../../../Assets/shopLocation/bananioutlet (2).jpeg";
const ShopLocation = () => {
  return (
    <div className="">
      <div
        className="bg-cover h-[70vh] bg-fixed    hidden md:block"
        style={{ backgroundImage: `url(${locationBannerDesk})` }}
      ></div>
      <div
        className="bg-cover h-[70vh]  bg-center flex items-center justify-center md:hidden block"
        style={{ backgroundImage: `url(${loction})` }}
      ></div>
      <section className="max-w-screen-2xl mx-auto py-20 px-10">
        <h1 className="text-2xl md:text-4xl text-center font-bold">
          Shop Opening and Closing Time Schedule
        </h1>
        <div className="py-20 grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            {" "}
            <img src={banani} alt="" />
          </div>

          <div>
            <h1 className="text-center text-2xl md:text-4xl  font-bold">
              Opening Hours
            </h1>

            <h3 className="px-10 pt-10 text-2xl md:text-3xl ">
              Opening Hours: 8.00 AM To 12.00 PM
            </h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShopLocation;
