import React, { useState } from "react";
import { CgCloseR } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useParams } from "react-router-dom";
const ShopCollectionHeader = () => {
  let [open, setOpen] = useState(false);

  return (
    <div className="shadow-sm w-full sticky -top-1  z-[99999] bg-white">
      <div className="max-w-screen-2xl mx-auto md:flex items-center md:justify-between  py-4 md:px-10 px-2 text-[#252525]">
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute left-5 top-6 cursor-pointer md:hidden"
        >
          {open ? (
            <CgCloseR className="md:ml-2" />
          ) : (
            <GiHamburgerMenu size={30} />
          )}
        </div>
        <div className=" flex justify-center md:justify-start">
          <div>
            <Link to="/shopCollections">
              <img
                src="https://i0.wp.com/wellmarry.com/wp-content/uploads/2022/12/Well-Marry-Logo-H-B.png?fit=7988%2C5780&ssl=1"
                alt=""
                className="w-20 md:w-32 mr-14"
              />
            </Link>
          </div>
        </div>
        <div
          className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-[#252525]"
        >
          <ul
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0
           w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in navbar-links  ${
             open ? "top-16 " : "top-[-490px]"
           }`}
          >
            <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px]">
              <Link to="/shopCollections/NewArrivals">New Arrivals</Link>
              {/* <div
                class="dropdown1 top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Apple
                </Link>
                <Link to="#" className="p-5">
                  Banana
                </Link>
                <Link to="#" className="p-5">
                  Orange
                </Link>
                <Link to="#" className="p-5">
                  Mango
                </Link>
                <Link to="#" className="p-5">
                  Watermelon
                </Link>
                <Link to="#" className="p-5">
                  Lemon
                </Link>
                <Link to="#" className="p-5">
                  Papaya
                </Link>
              </div> */}
            </li>
            <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px]">
              <Link to="/shopCollections/Shirts">Shirts</Link>
              {/* <div
                class="dropdown1 top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Coffee
                </Link>
                <Link to="#" className="p-5">
                  Milk
                </Link>
                <Link to="#" className="p-5">
                  Energy Drinks
                </Link>
                <Link to="#" className="p-5">
                  Coconut Water
                </Link>
                <Link to="#" className="p-5">
                  Milkshakes
                </Link>
                <Link to="#" className="p-5">
                  Fruit Punch
                </Link>
              </div> */}
            </li>
            <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px]">
              <Link to="/shopCollections/Outwear">Outerwear</Link>
              {/* <div
                class="dropdown1  top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Potato chips
                </Link>
                <Link to="#" className="p-5">
                  Popcorn
                </Link>
                <Link to="#" className="p-5">
                  Fruit Snacks
                </Link>
                <Link to="#" className="p-5">
                  Cookies
                </Link>
                <Link to="#" className="p-5">
                  Rice Cakes
                </Link>
                <Link to="#" className="p-5">
                  Protein Bars
                </Link>
              </div> */}
            </li>
            <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px] ">
              <Link
                to="/shopCollections/Accessories"
                className="hover:text-[#86c54f]"
              >
                Accessories
              </Link>
              {/* <div
                class="dropdown1  top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Tomato soup
                </Link>
                <Link to="#" className="p-5">
                  Potato Soup
                </Link>
                <Link to="#" className="p-5">
                  Lentil soup
                </Link>
                <Link to="#" className="p-5">
                  Mushroom soup
                </Link>
                <Link to="#" className="p-5">
                  Vegetable soup
                </Link>
                <Link to="#" className="p-5">
                  Corn chowder
                </Link>
              </div> */}
            </li>
            <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px] ">
              <Link
                to="/shopCollections/FeatureCollections"
                className="hover:text-[#86c54f]"
              >
                Featured Collections
              </Link>
              {/* <div
                class="dropdown1  top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Almond
                </Link>
                <Link to="#" className="p-5">
                  Peanuts
                </Link>
                <Link to="#" className="p-5">
                  Cashews
                </Link>
                <Link to="#" className="p-5">
                  Walnuts
                </Link>
                <Link to="#" className="p-5">
                  Macadamia nuts
                </Link>
                <Link to="#" className="p-5">
                  Kola nuts
                </Link>
              </div> */}
            </li>
            {/* <li class="navbar-dropdown md:ml-8 text-xl md:my-0 my-7 text-[34px] ">
              <Link
                to="/shopCollections/seaFood"
                className="hover:text-[#86c54f]"
              >
                SeaFood
              </Link>
              <div
                class="dropdown1  top-[100%] md:top-[250%] md:text-[24px] w-[280px]"
                onClick={() => setOpen(!open)}
              >
                <Link to="#" className="p-5">
                  Salmon
                </Link>
                <Link to="#" className="p-5">
                  Tuna
                </Link>
                <Link to="#" className="p-5">
                  Crab
                </Link>
                <Link to="#" className="p-5">
                  Mussels
                </Link>
                <Link to="#" className="p-5">
                  Cod
                </Link>
                <Link to="#" className="p-5">
                  Tilapia
                </Link>
                <Link to="#" className="p-5">
                  Halibut
                </Link>
                <Link to="#" className="p-5">
                  Octopus
                </Link>
                <Link to="#" className="p-5">
                  Wahoo
                </Link>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="hidden md:block ">
          <div class="  cursor-pointer">
            <div className="flex gap-4 justify-center items-center cursor-pointer">
              <Link to={`/shopCollections/shopCollectionProductSummery`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 48 48"
                >
                  <g fill="none">
                    <path d="M39 32H13L8 12h36l-5 20Z" />
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      d="M3 6h3.5L8 12m0 0l5 20h26l5-20H8Z"
                    />
                    <circle
                      cx="13"
                      cy="39"
                      r="3"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                    />
                    <circle
                      cx="39"
                      cy="39"
                      r="3"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                    />
                  </g>
                </svg>
              </Link>
              <Link to="/shopCollections/account">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11.5 14c4.14 0 7.5 1.57 7.5 3.5V20H4v-2.5c0-1.93 3.36-3.5 7.5-3.5m6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5M11.5 5A3.5 3.5 0 0 1 15 8.5a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8 8.5A3.5 3.5 0 0 1 11.5 5m0 1A2.5 2.5 0 0 0 9 8.5a2.5 2.5 0 0 0 2.5 2.5A2.5 2.5 0 0 0 14 8.5A2.5 2.5 0 0 0 11.5 6Z"
                  />
                </svg>
              </Link>
              <Link to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M338.29 338.29L448 448"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* new */}
      <div className="md:hidden block">
        <div class=" absolute top-[35%] right-[6%]  cursor-pointer">
          <div className="flex gap-4 justify-center items-center cursor-pointer">
            <Link to={`/shopCollections/shopCollectionProductSummery`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 48 48"
              >
                <g fill="none">
                  <path d="M39 32H13L8 12h36l-5 20Z" />
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                    d="M3 6h3.5L8 12m0 0l5 20h26l5-20H8Z"
                  />
                  <circle
                    cx="13"
                    cy="39"
                    r="3"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                  />
                  <circle
                    cx="39"
                    cy="39"
                    r="3"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                  />
                </g>
              </svg>
            </Link>
            <Link to="/shopCollections/account">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M11.5 14c4.14 0 7.5 1.57 7.5 3.5V20H4v-2.5c0-1.93 3.36-3.5 7.5-3.5m6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5M11.5 5A3.5 3.5 0 0 1 15 8.5a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8 8.5A3.5 3.5 0 0 1 11.5 5m0 1A2.5 2.5 0 0 0 9 8.5a2.5 2.5 0 0 0 2.5 2.5A2.5 2.5 0 0 0 14 8.5A2.5 2.5 0 0 0 11.5 6Z"
                />
              </svg>
            </Link>
            <Link to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                  stroke-width="32"
                  d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  stroke-width="32"
                  d="M338.29 338.29L448 448"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCollectionHeader;
