import React from "react";
import AllFoodPages from "../ShopCollectionsShared/AllFoodPages/AllFoodPages";

const Soups = () => {
  return (
    <>
      <AllFoodPages fruitsCategoryName="Soups section" />
    </>
  );
};

export default Soups;
