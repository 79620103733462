import React, { useEffect } from "react";
import ShopCollectionCard from "./ShopCollectionCard/ShopCollectionCard";
import ShopCollectionFoodSlider from "./ShopCollectionFoodSlider/ShopCollectionFoodSlider";
import ShopCollectionSlider from "./ShopCollectionSlider/ShopCollectionSlider";
import ShopCollectionVideo from "./ShopCollectionVideo/ShopCollectionVideo";
import { useLocation } from "react-router-dom";

const ShopCollectionHome = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <ShopCollectionSlider />
      <ShopCollectionCard />
      <ShopCollectionFoodSlider />
      <ShopCollectionVideo />
    </>
  );
};

export default ShopCollectionHome;
