import MainLayout from "../Layout/MainLayout";

import Home from "../Pages/Home/Home/Home";
import KRewards from "../Pages/Home/KRewards/KRewards";
import NotFound from "../Pages/NotFound/NotFound";

import Soups from "../ShopCollectionsPage/AllPages/Soups";
import ShopCollectionAccount from "../ShopCollectionsPage/ShopCollectionAccount/ShopCollectionAccount";
import ShopCollectionProductSummery from "../ShopCollectionsPage/ShopCollectionProductSummery/ShopCollectionProductSummery";
import ShopCollectionHome from "../ShopCollectionsPage/ShopCollectionsHome/ShopCollectionHome";

import AboutUs from "../Pages/Home/AboutUs/AboutUs";
import SingleProductDetails from "../ShopCollectionsPage/ShopCollectionsShared/AllFoodPages/SingleProductDetails";
import ShopLocation from "../Pages/Home/ShopLocation/ShopLocation";
import NewArrivals from "../ShopCollectionsPage/AllPages/NewArrivals";
import Shirts from "../ShopCollectionsPage/AllPages/Shirts";
import Outwear from "../ShopCollectionsPage/AllPages/Outwear";
import Accessories from "../ShopCollectionsPage/AllPages/Accessories";
import FeatureCollections from "../ShopCollectionsPage/AllPages/FeatureCollections";
import Bakery from "../Pages/Home/Foods/Bakery";
import SinglePrdouctDetails from "../Pages/Home/FoodPage/FoodSingleProductDetails";
import FoodSingleProductDetails from "../Pages/Home/FoodPage/FoodSingleProductDetails";
const { createBrowserRouter, useParams } = require("react-router-dom");
const { default: Main } = require("../Layout/Main");

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/foods",
        element: <Bakery />,
      },
      {
        path: "/foods/bakery/:FoodProductId",
        element: <FoodSingleProductDetails />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/KRewards",
        element: <KRewards />,
      },
      {
        path: "/shopLocation",
        element: <ShopLocation />,
      },
    ],
  },
  {
    path: "/shopCollections",
    element: <MainLayout />,
    children: [
      {
        path: "/shopCollections",
        element: <ShopCollectionHome />,
      },
      {
        path: "/shopCollections/NewArrivals",
        element: <NewArrivals />,
      },
      {
        path: "/shopCollections/Shirts",
        element: <Shirts />,
      },
      {
        path: "/shopCollections/Outwear",
        element: <Outwear />,
      },
      {
        path: "/shopCollections/Accessories",
        element: <Accessories />,
      },
      {
        path: "/shopCollections/FeatureCollections",
        element: <FeatureCollections />,
      },
      {
        path: "/shopCollections/soups",
        element: <Soups />,
      },
      {
        path: "/shopCollections/shopCollectionProductSummery",
        element: <ShopCollectionProductSummery />,
      },
      {
        path: "/shopCollections/account",
        element: <ShopCollectionAccount />,
      },
      {
        path: "/shopCollections/newArrivals/:productId",
        element: <SingleProductDetails />,
      },
    ],
  },
  // {
  //   path: "*",
  //   element: <NotFound />,
  // },
]);
