import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import React from "react";

import pants from "../../../../Assets/shopkcollections/newArrivalPic/update/pant orange.png";
import tshirt from "../../../../Assets/shopkcollections/newArrivalPic/update/tee biskit.png";
import pants2 from "../../../../Assets/shopkcollections/newArrivalPic/update/pant red.png";
import shirt from "../../../../Assets/shopkcollections/newArrivalPic/update/teeShirt black pro.png";
import sneakers from "../../../../Assets/shopkcollections/newArrivalPic/show1.jpeg";
import sneakers2 from "../../../../Assets/shopkcollections/newArrivalPic/huddi1.JPG";
import sneakers3 from "../../../../Assets/shopkcollections/newArrivalPic/weman1.jpg";
import hat from "../../../../Assets/shopkcollections/newArrivalPic/update/cap accent.png";
import top from "../../../../Assets/shopkcollections/newArrivalPic/update/cap red.png";

const ShopCollectionFoodSlider = () => {
  return (
    <>
      <div className="max-w-screen-2xl mx-auto">
        <Carousel
          plugins={[
            "centered",
            "infinite",
            "arrows",
            "autoplay",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 4,
              },
            },
          ]}
          breakpoints={{
            640: {
              plugins: [
                "centered",
                "infinite",
                "arrows",

                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
              ],
            },
            900: {
              plugins: [
                "centered",
                "infinite",
                "arrows",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 4,
                  },
                },
              ],
            },
          }}
        >
          <div className="px-2">
            <img alt="..." src={pants} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={tshirt} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={pants2} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={sneakers} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={sneakers2} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={sneakers3} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={hat} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={shirt} className="w-56" />
          </div>

          <div className="px-2">
            <img alt="..." src={shirt} className="w-56" />
          </div>
          <div className="px-2">
            <img alt="..." src={top} className="w-56" />
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default ShopCollectionFoodSlider;
