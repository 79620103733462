import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
const Star = ({ rating, reveiw }) => {
  //   console.log(rating, reveiw);
  const ratingStar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;
    return (
      <span key={index}>
        {rating >= index + 1 ? (
          <FaStar className="text-orange-300 text-[18px]" />
        ) : rating >= number ? (
          <FaStarHalfAlt className="text-orange-300 text-[18px]" />
        ) : (
          <AiOutlineStar className="text-orange-300 text-[18px]" />
        )}
      </span>
    );
  });
  return (
    <div>
      <div class="flex items-center">
        {" "}
        {ratingStar}{" "}
        <span class="ml-1 font-bold text-xl ">
          ({reveiw} customers reviews)
        </span>
      </div>

      <p></p>
    </div>
  );
};

export default Star;
