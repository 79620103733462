import React from "react";
import Banner from "../Banner/Banner";
import Category from "../Category/Category";
import Cauresol from "../Cauresol/Cauresol";
import Connected from "../Connected/Connected";
import Drinks from "../Drinks/Drinks";
import GetApp from "../GetApp/GetApp";
import Location from "../Location/Location";
import Rewards from "../Rewards/Rewards";
import Slider from "../Slider/Slider";

const Home = () => {
  return (
    <div className="">
      <Banner />
      <Category />
      <GetApp />
      <Drinks />
      <Rewards />
      {/* <Slider /> */}
      <Cauresol />
      <Connected />
      <Location />
    </div>
  );
};

export default Home;
