import React from "react";
import AllFoodPages from "../ShopCollectionsShared/AllFoodPages/AllFoodPages";

const NewArrivals = () => {
  return (
    <>
      <AllFoodPages fruitsCategoryName="New Arrivals" />
    </>
  );
};

export default NewArrivals;
