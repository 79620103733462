import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
const Connected = () => {
  return (
    <div className="max-w-screen-2xl mx-auto py-20  px-5">
      <div className="mb-10">
        <h1 className="text-[30px] text-center md:text-start md:text-[34px] lg:text-[44px] font-bold">
          Get Connected
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 gap-5 px-2 ">
        <div class="relative h-[400px]  overflow-hidden   cursor-pointer ">
          <img
            src="https://codingyaar.com/wp-content/uploads/card-image.jpg"
            class="card-img-top   mb-10  md:h-[400px] "
            alt="..."
          />
          <div class="absolute bg-black md:bottom-6 bottom-[30%] md:left-[55px] left-0   text-[#fff] md:w-96   w-full text-center h-14">
            <div className="flex items-center justify-center gap-5 ">
              <h5 class="">
                {" "}
                <FaFacebook size={30} />
              </h5>
              <span className="text-2xl my-3">
                <Link href="#" class="btn btn-primary">
                  facebook
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div class="relative  overflow-hidden  h-[400px] cursor-pointer">
          <img
            src="https://img.freepik.com/free-photo/mint-lemon-wooden-background_1205-12153.jpg?w=1380&t=st=1676462359~exp=1676462959~hmac=675e998f478df904b67ecd2407d68cb0ffb2b422bc175fd5e05746f09a62c60c"
            class="card-img-top  md:h-[400px] mb-10   "
            alt="..."
          />
          <div class="absolute bg-black md:bottom-6 md:left-[55px] left-0 bottom-[30%] text text-[#fff] md:w-96   w-full text-center h-14">
            <div className="flex items-center justify-center gap-5 ">
              <h5 class="">
                {" "}
                <FaInstagram size={30} />
              </h5>
              <span className="text-2xl my-3">
                <Link href="#" class="btn btn-primary">
                  Instagram
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div class="relative  overflow-hidden  h-[400px] cursor-pointer">
          <img
            src="https://img.freepik.com/free-photo/glasses-refreshing-hibiscus-ice-tea_23-2149893620.jpg?w=1380&t=st=1676462320~exp=1676462920~hmac=7f86528a75988f13860c1729d37fdf6af3e3ab4e2c7b28925948067ca25a121c"
            class="card-img-top  md:h-[400px] mb-10   "
            alt="..."
          />
          <div class="absolute bg-black md:bottom-6 md:left-[55px] bottom-[30%] text text-[#fff]  md:w-96  w-full text-center h-14">
            <div className="flex items-center justify-center gap-5 ">
              <h5 class="">
                {" "}
                <FaYoutube size={30} />
              </h5>
              <span className="text-2xl my-3">
                <Link href="#" class="btn btn-primary">
                  youtube
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connected;
